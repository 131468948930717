<template>
  <div>
    <sale-detail
      v-if="saleDetailDialog"
      :cart="sale"
      :visible="saleDetailDialog"
      :items="items"
      :instalments="instalments"
      @close="saleDetailDialog = false"
    />
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-start align-center">
            <v-col cols="12" md="6">
              <v-text-field
                filled
                outlined
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="sales"
          :search="search"
        >
          <template v-slot:[`item.price`]="{ item }">
            <span>R$ {{ formatPrice(item.price) }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text small @click="openSaleDetail(item)"
              ><v-icon small class="mr-2">mdi-eye</v-icon></v-btn
            >
            <v-btn
              v-if="item.status !== 'FATURADO'"
              text
              small
              :to="`/base/sale/${item.id}/edit`"
            >
              <v-icon small class="mr-2">mdi-pencil</v-icon></v-btn
            >
            <v-icon
              v-if="item.status !== 'FATURADO'"
              small
              class="mr-2"
              @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import SaleDetail from "./components/sale-detail.vue";
export default Vue.extend({
  name: "Vendas",
  components: { PageHeader, InternalMenu, SaleDetail },
  data: () => ({
    title: "Vendas",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Categorias de Produtos",
            to: "/base/categories",
            permission: "gerentor.product.categories.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Clientes",
            to: "/base/customers",
            permission: "gerentor.person.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Colaboradores",
            to: "/immobile/employees",
            permission: "genetor.person.employees.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Vendas",
        disabled: true,
        href: "/base/sales",
      },
    ],
    deleteDialog: false,
    sales: [],
    accounts: [],
    items: [],
    instalments: [],
    search: "",
    loading: false,
    saleId: "",
    invoiceModal: false,
    sale: null,
    payed: {
      accountId: "",
      email: "",
      password: "",
    },
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },

    headers: [
      {
        text: "Cliente",
        align: "start",
        value: "customer.person.corporateName",
        filtering: true,
      },
      {
        text: "Vendedor",
        align: "start",
        value: "salesman.person.corporateName",
        filtering: true,
      },
      {
        text: "Status",
        value: "status",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "amountLiquid",
        filtering: true,
      },
      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],
    saleDetailDialog: false,
    sale: null,
  }),
  methods: {
    openSaleDetail(item) {
      this.sale = item;
      this.getSaleItems();
      this.getInstalments();
      this.saleDetailDialog = true;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    openInvoiceModal(item) {
      this.sale = item;
      this.saleId = item.id;
      this.invoiceModal = true;
    },
    getAccounts() {
      this.loading = true;
      http.get("finance/accounts").then((data) => {
        this.accounts = data.data;
        this.loading = false;
      });
      this.loading = false;
    },
    closeInvoiceModal() {
      this.payed.email = "";
      this.payed.password = "";
      this.payed.accountId = "";
      this.sale = null;
      this.saleId = null;
      this.invoiceModal = false;
    },
    saleInvoice() {
      http.put(`gerentor/sales/${this.saleId}/invoice`, this.payed).then(() => {
        this.getItems();
      });
    },
    async getSaleItems() {
      const saleId = this.sale.id;
      await http.get(`gerentor/sale/${saleId}/items`).then((response) => {
        this.items = response?.data;
      });
    },

    async getInstalments() {
      const saleId = this.sale.id;
      await http
        .get(`finance/transactions?filter=saleId||eq||${saleId}`)
        .then((transaction) => {
          this.instalments = transaction.data;
        });
    },

    deleteOpen(itemId) {
      this.saleId = itemId;
      this.deleteDialog = true;
    },

    getItems() {
      this.loading = true;
      http.get("gerentor/sales").then(
        (data) => {
          this.sales = data.data;
          this.loading = false;
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text =
            "Hove um erro ao buscar os registros. Atualize a página por favor";
          this.snackbar.opened = true;
        }
      );
    },
    deleteItem() {
      http.delete(`gerentor/sales/${this.saleId}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
  },
  mounted() {
    this.getItems();
    this.getAccounts();
  },
});
</script>

