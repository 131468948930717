<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="titlePage" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>

    <v-dialog v-model="addProductModal" width="1100px">
      <v-container>
        <v-card style="padding: 10px !important">
          <v-card-title>Novo Item</v-card-title>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                outlined
                :items="products"
                v-model="productSelected"
                label="Produto"
                item-text="label"
                @change="selectItem"
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-text-field
                outlined
                v-model="itemProduct.quantity"
                label="Quantidade"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="mt-n6">
              <span>Custo UN R$</span>
              <money
                class="money"
                outlined
                v-model="itemProduct.unitCostPrice"
                label="Preço Custo"
                v-bind="money"
              ></money>
            </v-col>
            <v-col cols="2" class="mt-n6">
              <span>Venda UN R$</span>
              <money
                class="money"
                outlined
                v-model="itemProduct.salePrice"
                label="Preço Venda"
                v-bind="money"
              ></money>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn
                  :loading="loadingAction"
                  block
                  dark
                  class="light-green accent-4"
                  @click="addOrUpdateItem"
                  >Adicionar</v-btn
                >
              </v-col>
              <v-col cols="12" class="mt-n4">
                <v-btn block @click="closeModalItem">Cancelar</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>

    <v-row justify="center">
      <v-card>
        <v-card-text>
          <v-row class="mr-8 ml-8">
            <v-col cols="4">
              <span class="title-sale">Fornecedor</span>
              <p class="overline text-subtitle-2 mt-n1">{{ supplierName }}</p>
            </v-col>
            <v-col cols="4">
              <span class="title-sale">Total</span>
              <p class="overline text-subtitle-2 mt-n1">
                R$ {{ formatPrice(amountLiquid) }}
              </p></v-col
            >
            <v-col cols="4">
              <span class="title-sale">Plano de Pagamento</span>
              <p class="overline text-subtitle-2 mt-n1">
                {{ paymentFactory() }}
              </p></v-col
            >
          </v-row>
          <v-container v-if="!loading">
            <br />
            <v-stepper v-model="stage" vertical>
              <v-stepper-step :complete="stage > 1" step="1">
                Dados Gerais
                <small>
                  <v-btn v-if="stage > 1" icon x-small>
                    <v-icon @click="stage = 1">mdi-pencil</v-icon></v-btn
                  >
                </small>
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      outlined
                      :items="[
                        { id: 'ENTRADA_COMPRA', label: 'ENTRADA COMPRA' },
                        { id: 'ENTRADA', label: 'ENTRADA ACERTO ESTOQUE' },
                        { id: 'SAIDA', label: 'SAÍDA ACERTO ESTOQUE' },
                      ]"
                      item-text="label"
                      item-value="id"
                      v-model="type"
                      label="Tipo"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" v-if="type === 'ENTRADA_COMPRA'">
                    <v-autocomplete
                      outlined
                      :items="suppliers"
                      v-model="supplierId"
                      label="Fornecedor"
                      item-text="person.corporateName"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="3" v-if="type === 'ENTRADA_COMPRA'">
                    <v-text-field
                      outlined
                      v-model="document"
                      label="Nota"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3" v-if="type === 'ENTRADA_COMPRA'">
                    <v-text-field
                      outlined
                      v-model="documentSerie"
                      label="Série"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" v-if="type === 'ENTRADA_COMPRA'">
                    <v-text-field
                      outlined
                      v-model="documentKey"
                      label="Chave"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-btn
                  block
                  :disabled="
                    (!supplierId && type === 'ENTRADA_COMPRA') || !type
                  "
                  :loading="loadingAction"
                  color="primary"
                  @click="oneStage"
                >
                  Continuar
                </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="stage > 2" step="2">
                Produtos
              </v-stepper-step>

              <v-stepper-content step="2">
                <v-btn
                  @click="prepareModalItem(false)"
                  color="green accent-4"
                  block
                  dark
                >
                  Adicionar Novo Produto</v-btn
                >
                <v-data-table
                  :loading="loading"
                  :headers="headerItems"
                  :items="items"
                  disable-pagination
                  hide-default-footer
                  no-data-text="Adicione produtos para continuar"
                >
                  <template v-slot:[`item.unitCostPrice`]="{ item }">
                    <span>R$ {{ formatPrice(item.unitCostPrice) }}</span>
                  </template>

                  <template v-slot:[`item.totalCostPrice`]="{ item }">
                    <span>R$ {{ formatPrice(item.totalCostPrice) }}</span>
                  </template>

                  <template v-slot:[`item.salePrice`]="{ item }">
                    <span>R$ {{ formatPrice(item.salePrice) }}</span>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn text small @click="editItem(item)">
                      <v-icon small class="mr-2">mdi-pencil</v-icon></v-btn
                    >
                    <v-icon small class="mr-2" @click="removeItem(item)"
                      >mdi-eraser</v-icon
                    >
                  </template>
                </v-data-table>

                <v-btn
                  :loading="loadingAction"
                  :disabled="!items.length"
                  block
                  color="primary"
                  @click="setProducts"
                >
                  Salvar e Continuar
                </v-btn>
                <v-btn class="mt-2" block @click="stage = 1" text>
                  Voltar
                </v-btn>
              </v-stepper-content>

              <v-stepper-step
                v-if="type === 'ENTRADA_COMPRA'"
                :complete="stage > 3"
                step="3"
              >
                Forma de Pagamento
              </v-stepper-step>

              <v-stepper-content step="3" v-if="type === 'ENTRADA_COMPRA'">
                <p class="text-caption ml-4">Total a Pagar</p>
                <p class="overline text-h6 mt-n5 ml-4 mb-8">
                  R$ {{ formatPrice(amountLiquid) }}
                </p>
                <v-row>
                  <v-col cols="7">
                    <v-select
                      outlined
                      :items="plans"
                      item-text="label"
                      item-value="id"
                      v-model="paymentPlan"
                      label="Plano de Pagamento"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-select>
                  </v-col>
                  <v-col cols="4" v-if="paymentPlan === 'AVISTA'">
                    <v-autocomplete
                      outlined
                      :items="paymentMethods"
                      v-model="paymentMethodId"
                      label="Forma de Pagamento"
                      item-text="title"
                      item-value="id"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="paymentPlan === 'PARCELADO_COM_ENTRADA'">
                  <v-col cols="4" class="mt-n6">
                    <span>Entrada R$</span>
                    <money
                      class="money"
                      v-bind="money"
                      v-model="amountPaid"
                    ></money>
                  </v-col>
                  <v-col cols="5">
                    <v-autocomplete
                      outlined
                      :items="paymentMethods"
                      v-model="paymentMethodId"
                      label="Forma de Pagamento Entrada"
                      item-text="title"
                      item-value="id"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="paymentPlan && paymentPlan !== 'AVISTA'">
                  <v-col cols="2">
                    <v-text-field
                      type="number"
                      outlined
                      v-model="frequencyTotal"
                      label="Total de Parcelas"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-select
                      outlined
                      :items="[
                        { id: 'W', label: 'Semanal' },
                        { id: 'M', label: 'Mensal' },
                        { id: 'Y', label: 'Anual' },
                      ]"
                      item-text="label"
                      item-value="id"
                      v-model="frequencyType"
                      label="Prazo Parcelas"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-select>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      outlined
                      label="Vencimento Inicial"
                      v-model="initialDueDate"
                      type="date"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-autocomplete
                      outlined
                      :items="paymentMethods"
                      v-model="instalmentMethodId"
                      label="Forma de Pagamento Parcelas"
                      item-text="title"
                      item-value="id"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-btn
                  :loading="loadingAction"
                  block
                  color="primary"
                  @click="setPayment"
                >
                  Registrar Pagamento
                </v-btn>
                <v-btn block class="mt-2" @click="stage = 2"> Voltar </v-btn>
              </v-stepper-content>

              <v-stepper-step
                :complete="stage > 4"
                step="4"
                v-if="
                  type === 'ENTRADA_COMPRA' &&
                  (paymentPlan === 'PARCELADO_COM_ENTRADA' ||
                    paymentPlan === 'PARCELADO_SEM_ENTRADA')
                "
              >
                Parcelas
              </v-stepper-step>
              <v-stepper-content
                step="4"
                v-if="
                  type === 'ENTRADA_COMPRA' &&
                  (paymentPlan === 'PARCELADO_COM_ENTRADA' ||
                    paymentPlan === 'PARCELADO_SEM_ENTRADA')
                "
              >
                <v-card class="mx-auto">
                  <v-data-table
                    :loading="loading"
                    :headers="hins"
                    :items="instalments"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:[`item.amount`]="{ item }">
                      <v-edit-dialog
                        :return-value.sync="item.amount"
                        @save="save(item)"
                        @cancel="cancel"
                        @open="open"
                        @close="close"
                      >
                        R$ {{ formatPrice(item.amount) }}
                        <template v-slot:input>
                          <money
                            v-model="item.amount"
                            label="Edit"
                            single-line
                            v-bind="money"
                          ></money>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:[`item.dueDate`]="{ item }">
                      <v-edit-dialog
                        :return-value.sync="item.dueDate"
                        @save="save(item)"
                        @cancel="cancel"
                        @open="open"
                        @close="close"
                      >
                        {{ dateFormat(item.dueDate) }}
                        <template v-slot:input>
                          <v-text-field
                            v-model="item.dueDate"
                            label="Novo Vencimento"
                            single-line
                            type="date"
                          ></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template v-slot:[`item.number`]="{ item }">
                      <span>{{
                        `${item.frequencyNumber}/${item.frequencyTotal}`
                      }}</span>
                    </template>
                  </v-data-table>
                  <v-card-actions>
                    <v-col cols="12">
                      <v-btn
                        :loading="loadingAction"
                        dark
                        width="100%"
                        color="#2ca01c"
                        @click="saveAll()"
                        >Finalizar</v-btn
                      >
                    </v-col>
                  </v-card-actions>
                </v-card>
                <v-btn text @click="stage = 3"> Voltar</v-btn>
              </v-stepper-content>
            </v-stepper>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="blue darken-1" to="/base/stock-transactions">
                Voltar para Compras
              </v-btn>
              <v-btn
                v-if="transactionId"
                dark
                :loading="loadingAction"
                width="200px"
                color="#2ca01c"
                @click="stage = 1"
              >
                Reiniciar
              </v-btn>
            </v-card-actions>
          </v-container>
          <v-container v-else>
            <v-col cols="12">
              <v-skeleton-loader
                height="400px"
                width="800px"
                type="article, actions"
              ></v-skeleton-loader>
            </v-col>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import moment from "moment";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
export default Vue.extend({
  title: "Estoque",
  components: { Money, PageHeader, InternalMenu },
  data: () => ({
    titlePage: "Nova Transação de Estoque",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Vendas",
            to: "/base/sales",
            permission: "gerentor.sales.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Estoque",
        disabled: false,
        href: "/base/stock-transactions",
      },
      {
        text: "Nova Transação de Estoque",
        disabled: true,
        href: "/base/sale/create",
      },
    ],

    valid: true,
    accounts: [],
    movements: [],
    paymentMethods: [],
    categories: [],
    members: [],
    suppliers: [],
    employees: [],
    products: [],
    items: [],
    search: "",
    loading: false,
    loadingAction: false,
    stage: 1,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    id: "",
    title: "",
    referenceDate: null,
    amount: "",
    categoryId: null,
    accountId: null,
    paymentMethodId: null,
    title: "",
    type: null,
    salesmanId: null,
    dueDate: new Date(),
    supplierId: null,
    supplierId: null,
    paid: true,
    paymentPlan: "AVISTA",
    frequencyType: null,
    frequencyTotal: 3,
    openDialog: false,
    fullScreen: false,
    instalments: [],
    instalmentDialog: false,
    initialAmount: 0,
    amountPaid: 0,
    initialDueDate: null,
    instalmentMethodId: null,
    amountLiquid: 0,
    frequencyCalc: 0,
    transactionId: null,
    supplierName: "-",
    itemId: null,
    productSelected: null,
    document: null,
    documentKey: null,
    documentSerie: null,
    itemProduct: {
      productId: null,
      transactionId: null,
      unitCostPrice: "",
      salePrice: "",
      quantity: 1,
    },
    hins: [
      {
        text: "Nº",
        align: "start",
        value: "number",
        filtering: false,
      },
      {
        text: "Vencimento",
        align: "start",
        value: "dueDate",
        filtering: true,
      },
      {
        text: "Valor",
        align: "start",
        value: "amount",
        filtering: true,
      },
      {
        text: "Forma Pagamento",
        align: "start",
        value: "paymentMethod.title",
        filtering: true,
      },
    ],
    plans: [
      {
        id: "AVISTA",
        label: "Avista",
      },
      {
        id: "PARCELADO_COM_ENTRADA",
        label: "Parcelado C/ Entrada",
      },
      {
        id: "PARCELADO_SEM_ENTRADA",
        label: "Parcelado S/ Entrada",
      },
    ],
    headers: [],
    headerItems: [
      {
        text: "Descrição",
        align: "start",
        value: "product.name",
        filtering: true,
      },
      {
        text: "QTD",
        align: "start",
        value: "quantity",
        filtering: true,
      },
      {
        text: "Custo UN R$",
        align: "start",
        value: "unitCostPrice",
        filtering: true,
      },
      {
        text: "Custo Total R$",
        align: "start",
        value: "totalCostPrice",
        filtering: true,
      },
      {
        text: "Venda R$",
        align: "start",
        value: "salePrice",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
    /* modals */
    addProductModal: false,
    hasSale: true,

    /* Instalments */
    instalments: [],
  }),
  methods: {
    selectItem() {
      this.itemProduct.productId = this.productSelected.id;
      this.itemProduct.unitCostPrice = this.productSelected.costPrice;
      this.itemProduct.salePrice = this.productSelected.price;
    },
    prepareModalItem(editiMode) {
      if (!editiMode) this.filterProducts();
      this.addProductModal = true;
    },
    filterProducts() {
      const itemIds = this.items.map((a) => a.productId);
      this.products = this.products.filter((a) => !itemIds.includes(a.id));
    },
    paymentFactory() {
      if (!this.paymentPlan) return "-";
      if (this.paymentPlan === "AVISTA") return "Avista";
      if (this.paymentPlan === "PARCELADO_COM_ENTRADA" && this.frequencyTotal)
        return `Entrada de R$ ${this.formatPrice(this.amountPaid)} + ${
          this.frequencyTotal
        } Parcelas`;

      if (this.paymentPlan === "PARCELADO_SEM_ENTRADA" && this.frequencyTotal)
        return `Parcelado S/ Entrada em ${this.frequencyTotal}x`;
      return "-";
    },
    editItem(item) {
      this.itemProduct = item;
      this.itemId = item.id;
      this.productSelected = item.product;
      this.prepareModalItem();
    },
    closeModalItem() {
      this.itemId = null;
      this.itemProduct.productId = null;
      this.itemProduct.quantity = 1;
      this.itemProduct.unitCostPrice = "";
      this.itemProduct.salePrice = "";
      this.addProductModal = false;
      this.getProducts();
      this.getSaleItems();
    },
    getSaleItems() {
      http
        .get(`gerentor/stock-transaction/${this.transactionId}/items`)
        .then((response) => {
          this.items = response?.data;
        });
    },
    removeItem(item) {
      http
        .delete(
          `gerentor/stock-transaction/${item.transactionId}/items/${item.id}`
        )
        .then(() => {
          http
            .get(`gerentor/stock-transaction/${item.transactionId}/items`)
            .then((response) => {
              this.items = response?.data;
            });
        });
    },
    async setPayment() {
      const payload = {
        paymentMethodId: this.paymentMethodId,
        paymentPlan: this.paymentPlan,
        instalmentMethodId: this.instalmentMethodId,
        initialDueDate: this.initialDueDate,
        amountPaid: this.amountPaid,
        frequencyType: this.frequencyType,
        frequencyTotal: this.frequencyTotal,
      };
      this.loadingAction = true;
      await http
        .put(
          `gerentor/stock-transactions/${this.transactionId}/payment`,
          payload
        )
        .then(async (res) => {
          const { data } = res;
          this.stage = data.stage;
          if (this.paymentPlan === "AVISTA") {
            this.snackbar.text = "Venda Inserida com Sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            setTimeout(() => {
              this.$router.push("/base/stock-transactions");
            }, 1600);
          } else {
            this.paymentPlan = data.paymentPlan;
            if (
              this.paymentPlan === "PARCELADO_SEM_ENTRADA" ||
              this.paymentPlan === "AVISTA"
            ) {
              this.frequencyTotal = data.frequencyTotal;
            }
            if (this.paymentPlan === "PARCELADO_COM_ENTRADA") {
              this.frequencyTotal = data.frequencyTotal - 1;
            }

            this.amountLiquid = data.amountLiquid;
            this.amountPaid = data.amountPaid;
            this.frequencyType = data.frequencyType;
            await http
              .get(`finance/transactions?filter=purchaseId||eq||${data.id}`)
              .then((transaction) => {
                this.instalments = transaction.data;
              });
          }
          this.loadingAction = false;
        });
      this.loadingAction = false;
    },
    async saveAll() {
      this.loadingAction = true;
      for await (const instalment of this.instalments) {
        await http.put(`finance/transactions/${instalment.id}`, instalment);
      }
      await http
        .patch(`gerentor/stock-transactions/${this.transactionId}`, {
          status: "FECHADO",
          stage: 5,
        })
        .then((res) => {
          this.snackbar.text = "Parcelas Atualizadas com Sucesso";
          this.snackbar.color = "success";
          this.snackbar.opened = true;
          this.$router.push("/base/stock-transactions");
        });
      this.loadingAction = false;
    },
    setProducts() {
      this.loadingAction = true;
      http
        .put(`gerentor/stock-transactions/${this.transactionId}/set-items`)
        .then((res) => {
          const { data } = res;
          this.stage = data.stage;
          this.amountLiquid = data.amountLiquid;
          if (this.stage === 5) {
            this.$router.push("/base/stock-transactions");
          }
        });
      this.loadingAction = false;
    },
    addOrUpdateItem() {
      if (!this.transactionId) {
        this.snackbar.color = "orange";
        this.snackbar.text = "Pra Adicionar um Produto Inicie a Venda";
        this.snackbar.opened = true;
        return;
      }
      this.itemProduct.totalCostPrice =
        this.itemProduct.unitCostPrice * this.itemProduct.quantity;
      if (this.itemId) {
        this.loadingAction = true;
        http
          .put(
            `gerentor/stock-transaction/${this.transactionId}/items/${this.itemId}`,
            this.itemProduct
          )
          .then(
            () => {
              this.loadingAction = false;
              this.snackbar.color = "success";
              this.snackbar.text = "Produto Alterado com Sucesso!";
              this.snackbar.opened = true;
              this.closeModalItem();
            },
            () => {
              this.loadingAction = false;
              this.snackbar.color = "red";
              this.snackbar.text =
                "Ocorreu um erro ao adicionar o item. Favor tentar novamente";
              this.snackbar.opened = true;
            }
          );
      } else {
        this.loadingAction = true;
        http
          .post(
            `gerentor/stock-transaction/${this.transactionId}/items`,
            this.itemProduct
          )
          .then(
            () => {
              this.loadingAction = false;
              this.snackbar.color = "success";
              this.snackbar.text = "Produto Adicionado com Sucesso!";
              this.snackbar.opened = true;
              this.closeModalItem();
            },
            () => {
              this.loadingAction = false;
              this.snackbar.color = "red";
              this.snackbar.text =
                "Ocorreu um erro ao adicionar o item. Favor tentar novamente";
              this.snackbar.opened = true;
            }
          );
      }
    },
    save(item) {
      if (this.paymentPlan === "PARCELADO_COM_ENTRADA")
        this.calculateInstalmentOn(item);

      if (this.paymentPlan === "PARCELADO_SEM_ENTRADA")
        this.calculateInstalmentOff(item);
    },
    calculateInstalmentOff(item) {
      let start = item.frequencyNumber - 1;
      const total = Number.parseFloat(this.amountLiquid);
      const interval = this.frequencyTotal - item.frequencyNumber;

      let amountCalc = 0;
      let point = 0;
      amountCalc += Number.parseFloat(this.instalments[0].amount);
      while (point < start) {
        point++;
        amountCalc += Number.parseFloat(this.instalments[point].amount);
      }
      const diference = total - amountCalc;
      const parcel = diference / interval;

      while (start < this.frequencyTotal) {
        start++;
        this.instalments[start - 1].amount = parcel;
      }
    },
    calculateInstalmentOn(item) {
      let start = item.frequencyNumber - 1;
      const frequency = Number.parseInt(this.frequencyTotal) + 1;
      const total = Number.parseFloat(this.amountLiquid);
      const interval = frequency - item.frequencyNumber;

      let amountCalc = 0;
      let point = 0;
      amountCalc += Number.parseFloat(this.instalments[0].amount);
      while (point < start) {
        point++;
        amountCalc += Number.parseFloat(this.instalments[point].amount);
      }
      const diference = total - amountCalc;
      const parcel = diference / interval;

      while (start < frequency) {
        start++;
        this.instalments[start - 1].amount = parcel;
      }
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {
      console.log("Dialog closed");
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    async getSale() {
      this.loading = true;
      const transactionId = this.$route.params.id;
      if (transactionId) {
        this.transactionId = transactionId;
        await http
          .get(`gerentor/stock-transactions/${transactionId}`)
          .then(async (res) => {
            const { data } = res;
            this.supplierId = data.supplierId;
            this.type = data.type;
            this.stage = data.stage;
            this.transactionId = data.id;
            this.paymentPlan = data.paymentPlan;
            this.paymentMethodId = data.paymentMethodId;
            this.instalmentMethodId = data.instalmentPaymentMethodId;
            this.amountLiquid = data.amountLiquid;
            if (
              this.paymentPlan === "PARCELADO_SEM_ENTRADA" ||
              this.paymentPlan === "AVISTA"
            ) {
              this.frequencyTotal = data.frequencyTotal;
            }
            if (this.paymentPlan === "PARCELADO_COM_ENTRADA") {
              this.frequencyTotal = data.frequencyTotal - 1;
            }
            this.amountLiquid = data.amountLiquid;
            this.amountPaid = data.amountPaid;
            this.frequencyType = data.frequencyType;
            this.supplierName = data?.supplier?.person?.corporateName;
            await http
              .get(`gerentor/stock-transaction/${data.id}/items`)
              .then((response) => {
                this.items = response?.data;
              });
            await http
              .get(`finance/transactions?filter=purchaseId||eq||${data.id}`)
              .then((transaction) => {
                this.instalments = transaction.data;
                if (this.instalments?.length > 1) {
                  const one = this.instalments.find(
                    (d) => d.frequencyNumber == 2
                  );
                  this.initialDueDate = one?.dueDate;
                }
              });
          });
      }
      this.loading = false;
    },
    oneStage() {
      this.loadingAction = true;
      const payload = {
        supplierId: this.supplierId,
        salesmanId: this.salesmanId,
        type: this.type,
        document: this.document,
        documentSerie: this.documentSerie,
        documentKey: this.documentKey,
        stage: 2,
      };
      if (this.transactionId) {
        http
          .put(`gerentor/stock-transactions/${this.transactionId}`, payload)
          .then((res) => {
            const { data } = res;
            this.stage = data.stage;
            this.getSale();
            //this.supplierName = data?.customer?.person?.corporateName;
          });
      } else {
        http.post(`gerentor/stock-transactions`, payload).then((res) => {
          const { data } = res;
          this.$router.push(`/base/stock-transactions/${data.id}/edit`);
          this.transactionId = data.id;
          this.stage = data.stage;
          this.getSale();

          /*     this.supplierId = data.supplierId;
          this.salesmanId = data.salesmanId;
          this.type = data.type;
          this.stage = data.stage;*/
        });
      }
      this.loadingAction = false;
    },

    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },
    formatPrice(value, type) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      if (!type) return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return type === "C"
        ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        : "-" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(referenceDate) {
      return moment(referenceDate).format("DD/MM/YYYY");
    },
    getEmployees() {
      this.loading = true;
      http.get("gerentor/employees").then((data) => {
        this.employees = data.data;
        this.loading = false;
      });
      this.loading = false;
    },
    getPayments() {
      this.loading = true;
      http.get("finance/payment-methods").then((data) => {
        this.paymentMethods = data.data;
        this.loading = false;
      });
    },
    getAccounts() {
      this.loading = true;
      http.get("finance/accounts").then((data) => {
        this.accounts = data.data;
        this.loading = false;
      });
    },
    getProducts() {
      this.loading = true;
      http.get("products").then((data) => {
        this.products = data.data;
        this.loading = false;
      });
    },
    getCustomers() {
      this.loading = true;
      http.get("gerentor/suppliers").then((data) => {
        this.suppliers = data.data;
        this.loading = false;
      });
    },
    getCategories() {
      this.loading = true;
      http.get("finance/categories").then((data) => {
        this.categories = data.data;
        this.loading = false;
      });
    },

    registerOrUpdate() {
      this.loadingAction = true;
      let payload = {
        referenceDate: this.referenceDate,
        paid: this.paid,
        datetime: new Date(),
        dueDate: this.dueDate,
        paymentPlan: this.paymentPlan,
        type: this.type,
        paymentMethodId: this.paymentMethodId,
        title: this.title,
        accountId: this.accountId,
        categoryId: this.categoryId,
        amount: this.amount,
        supplierId: this.supplierId,
        supplierId: this.supplierId,
        frequencyType: this.frequencyType,
        frequencyTotal: this.paymentPlan === "S" ? 1 : this.frequencyTotal,
        frequencyNumber: this.paymentPlan === "S" ? 1 : null,
      };

      http.post("finance/transactions", payload).then(
        (res) => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Inserido com Sucesso!";
          this.snackbar.opened = true;
          this.loadingAction = false;
          if (this.paymentPlan === "I") {
            this.instalments = res.data;
            this.instalmentDialog = true;
          }
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
          this.snackbar.opened = true;
          this.loadingAction = false;
        }
      );
    },
    updatePaid(id, e) {
      this.loadingAction = true;
      http.patch(`finance/transactions/${id}`, { paid: e }).then(
        () => {
          this.loadingAction = false;
          this.snackbar.text = "Transação Atualizada";
          this.snackbar.color = "success";
          this.snackbar.opened = true;
        },
        () => {
          this.loadingAction = false;
          this.snackbar.text = "Erro ao Atualizar Transação";
          this.snackbar.color = "red";
          this.snackbar.opened = true;
        }
      );
    },
    closeDialog() {
      this.$refs.form.reset();
      this.id = "";
      this.amount = "";
      this.openDialog = false;
    },
  },

  async mounted() {
    await this.getSale();
    this.getEmployees();
    this.getAccounts();
    this.getCategories();
    this.getPayments();
    this.getProducts();
    this.getCustomers();
  },
});
</script>
<style>
.money {
  background-color: rgb(0, 126, 0);
  height: 57px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
.title-sale {
  font-size: 10px;
  color: grey;
}
.card-sale {
  font-size: 19px !important;
}
</style>