<template>
  <div>
    <v-dialog v-model="imageModal" width="800">
      <v-btn icon>
        <v-icon @click="imageModal = false">mdi-close</v-icon>
      </v-btn>
      <v-img :src="imageZoom"></v-img>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0">
          <v-row class="d-flex justify-space-around">
            <v-col cols="4">
              <v-text-field
                outlined
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                height="40"
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="2">
              <v-btn color="primary" @click="openDialog = true">Novo</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="immobiles"
          :search="search"
        >
          <template v-slot:[`item.price`]="{ item }">
            <span
              >R$
              {{
                formatPrice(
                  item.businesType === "VENDA" ? item.salePrice : item.rentPrice
                )
              }}</span
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >

            <v-icon
              v-if="verifyDeletePermission()"
              small
              class="mr-2"
              @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog
        v-model="openDialog"
        width="1000"
        :fullscreen="fullScreen"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            flat
            style="padding-right: 10px !important"
            dark
            color="primary"
          >
            <v-toolbar-title class="overline">Imóveis</v-toolbar-title>
            <v-btn icon right absolute dark>
              <v-icon
                @click="fullScreen ? (fullScreen = false) : (fullScreen = true)"
                >{{ setIconScreen() }}</v-icon
              >
              <v-icon @click="closeDialog()">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-tabs v-model="tab" centered>
                <v-tabs-slider></v-tabs-slider>
                <v-tab class="no-uppercase" href="#tab-1">
                  Dados Principais
                </v-tab>
                <v-tab class="no-uppercase" href="#tab-2">Complemento </v-tab>
                <v-tab class="no-uppercase" href="#tab-3">Imagens</v-tab>
              </v-tabs>
              <br />
              <v-tabs-items v-model="tab">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-tab-item :value="'tab-1'">
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-select
                          outlined
                          :items="types"
                          item-value="id"
                          item-text="label"
                          label="Tipo"
                          v-model="immobile.type"
                          :rules="[(v) => !!v || 'Campo obrigatório']"
                          required
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="4">
                        <v-select
                          outlined
                          :items="businesTypes"
                          item-value="id"
                          item-text="label"
                          label="Disponibilidade"
                          v-model="immobile.businesType"
                          :rules="[(v) => !!v || 'Campo obrigatório']"
                          required
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="4">
                        <v-select
                          outlined
                          :items="[
                            'Uso Residencial',
                            'Uso Comrecial',
                            'Uso Livre',
                          ]"
                          label="Finalidade"
                          v-model="immobile.occupationType"
                          :rules="[(v) => !!v || 'Campo obrigatório']"
                          required
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Descrição"
                          v-model="immobile.description"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" class="mt-n8">
                        <v-text-field
                          label="Valor Venda R$"
                          outlined
                          v-money="money"
                          v-model.lazy="immobile.salePrice"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3" class="mt-n8">
                        <v-text-field
                          label="Valor Aluguel R$"
                          outlined
                          v-money="money"
                          v-model.lazy="immobile.rentPrice"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="2"
                        class="mt-n8"
                        v-if="immobile.businesType !== 'VENDA'"
                      >
                        <v-text-field
                          outlined
                          label="Máximo Contrato (meses)"
                          type="number"
                          v-model="immobile.maximumContract"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        :sm="immobile.businesType !== 'VENDA' ? 6 : 8"
                        class="mt-n8"
                      >
                        <v-text-field
                          labbel="Cartório"
                          outlined
                          v-model="immobile.registryName"
                          label="Cartório"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="4" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Matrícula"
                          v-model="immobile.registerNumber"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="8" class="mt-n8">
                        <v-autocomplete
                          outlined
                          :items="owners"
                          item-text="person.corporateName"
                          item-value="id"
                          label="Proprietário Principal"
                          v-model="immobile.ownerMainId"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="4" class="mt-n8">
                        <v-switch
                          v-model="immobile.newProperty"
                          :label="`Imóvel Novo? - ${
                            immobile.newProperty ? 'SIM' : 'NÃO'
                          }`"
                        ></v-switch>
                      </v-col>

                      <v-col cols="12" sm="3" class="mt-n8">
                        <v-select
                          outlined
                          v-model="immobile.requiredGuarantee"
                          :items="guarantees"
                          item-text="text"
                          item-value="value"
                          label="Tipo de Garantia"
                          required
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        v-if="
                          immobile.requiredGuarantee === 'DEPOSITO_ANTECIPADO'
                        "
                        class="mt-n8"
                      >
                        <v-text-field
                          outlined
                          v-money="money"
                          v-model.lazy="immobile.advanceAmount"
                          required
                          label="Valor Garantia R$"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="2" class="mt-n8">
                        <v-text-field
                          outlined
                          v-money="decimal"
                          v-model.lazy="immobile.buildingArea"
                          label="Área Total (m2)"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="2" class="mt-n8">
                        <v-text-field
                          outlined
                          v-money="money"
                          v-model.lazy="immobile.condominiumFee"
                          label="Taxa Condominio R$"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" class="mt-n8">
                        <v-text-field
                          outlined
                          v-money="money"
                          v-model.lazy="immobile.iptu"
                          label="Valor IPTU R$"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        :disabled="!valid"
                        :loading="loadingAction"
                        color="success"
                        @click="validate()"
                      >
                        Salvar
                      </v-btn>
                    </v-card-actions>
                  </v-tab-item>
                  <v-tab-item :value="'tab-2'">
                    <v-row>
                      <v-col cols="12" sm="7">
                        <v-autocomplete
                          outlined
                          :items="condominiums"
                          label="Condominio"
                          item-text="name"
                          item-value="id"
                          @blur="searchCondominium()"
                          v-model="immobile.condominiumId"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <v-text-field
                          outlined
                          v-mask="'#####-###'"
                          label="CEP"
                          v-model="immobile.address.zipCode"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Logradouro"
                          v-model="immobile.address.street"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Número"
                          v-model="immobile.address.number"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Complemento"
                          v-model="immobile.address.complement"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Bairro"
                          v-model="immobile.address.zone"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="mt-n8">
                        <v-text-field
                          outlined
                          label="Cidade"
                          v-model="immobile.address.city"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" class="mt-n8">
                        <v-text-field
                          outlined
                          label="UF"
                          v-model="immobile.address.state"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="mt-n8">
                        <v-textarea
                          outlined
                          label="Descrição Completa"
                          :rows="4"
                          v-model="immobile.notes"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        :loading="loadingAction"
                        color="success"
                        @click="validate()"
                      >
                        Salvar
                      </v-btn>
                    </v-card-actions>
                  </v-tab-item>

                  <v-tab-item :value="'tab-3'" color="#0d0d0d">
                    <v-row>
                      <v-col cols="12" md="7" class="mb-n3">
                        <div v-for="(im, ind) in attachments" :key="ind">
                          <v-card width="300">
                            <v-img
                              :src="im.url"
                              class="white--text align-end"
                              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                              height="200px"
                              width="300"
                            >
                              <v-card-title
                                v-text="im.description"
                              ></v-card-title>
                            </v-img>

                            <v-card-actions>
                              <v-spacer></v-spacer>

                              <v-btn icon @click="removeAttachment(im)">
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>

                              <v-btn
                                icon
                                @click="
                                  (imageZoom = im.url), (imageModal = true)
                                "
                              >
                                <v-icon>mdi-magnify-plus</v-icon>
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </div>
                      </v-col>

                      <v-col cols="12" md="4">
                        <span class="label-outlined">Nova Imagem</span>
                        <br />
                        <v-btn
                          text
                          class="black--text"
                          @click.native="openFileDialog"
                        >
                          {{ fileName ? fileName : "Imagem" }}
                          <v-icon right dark>mdi-camera</v-icon>
                        </v-btn>
                        <input
                          ref="fileRef"
                          type="file"
                          id="file-upload"
                          style="display: none"
                          @change="handleFileUpload"
                        />
                        <v-img
                          height="300"
                          width="300"
                          :src="urlPreview"
                          @click.native="openFileDialog"
                        ></v-img>
                        <v-text-field
                          outlined
                          label="Desrcrição"
                          v-model="imageName"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        :disabled="!valid"
                        :loading="loadingAction"
                        color="success"
                        @click="validate()"
                      >
                        Salvar
                      </v-btn>
                    </v-card-actions>
                  </v-tab-item>
                </v-form>
              </v-tabs-items>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import axios from "axios";
import { VMoney } from "v-money";
import TransformCurrencyToDecimalHelper from "@/helpers/transform-currency";

export default Vue.extend({
  name: "Company",
  directives: { money: VMoney },
  data: () => ({
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    decimal: {
      decimal: ".",
      thousands: "",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    tab: null,
    imageZoom: "",
    imageModal: false,
    deleteDialog: false,
    immobiles: [],
    owners: [],
    condominiums: [],
    attachments: [],
    file: "",
    imageName: "",
    fileName: "",
    urlPreview:
      "https://pedbem.blob.core.windows.net/pedbem/photo-99135_640.png",
    types: [
      {
        id: "Casa",
        label: "Casa",
      },
      {
        id: "Apartamento",
        label: "Apartamento",
      },
      {
        id: "Kitnet",
        label: "Kitnet",
      },
      {
        id: "Condominio",
        label: "Condominio",
      },
      {
        id: "Chacara",
        label: "Chácara",
      },
      {
        id: "Fazenda",
        label: "Fazenda",
      },
      {
        id: "Fazenda",
        label: "Fazenda",
      },
      {
        id: "Barracão",
        label: "Barracão",
      },
      {
        id: "Sala Comercial",
        label: "Sala Comercial",
      },
      {
        id: "Área de Lazer",
        label: "Área de Lazer",
      },
      {
        id: "Terreno",
        label: "Terreno",
      },
      {
        id: "Garagem",
        label: "Garagem",
      },
    ],
    businesTypes: [
      {
        id: "ALUGUEL",
        label: "Aluguel",
      },
      {
        id: "VENDA",
        label: "Venda",
      },
    ],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    maskPhone: "(##)####-####",
    maskCpf: "###.###.###-##",
    maskCnpj: "##.###.###/####-##",
    maskPhoneMobile: "(##)#####-####",
    banks: [],
    notaries: [],
    valid: true,
    immobile: {
      active: true,
      advanceAmount: "",
      notes: "",
      buildingArea: "",
      description: "",
      condominiumId: null,
      type: null,
      businesType: "",
      salePrice: "",
      rentPrice: "",
      occupationType: "Residencial",
      requiredGuarantee: "",
      ownerMainId: null,
      condominiumFee: null,
      maximumContract: 12,
      newProperty: false,
      iptu: "",
      address: {
        street: "",
        number: "",
        complement: "",
        zone: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },

    id: "",
    openDialog: false,
    fullScreen: false,
    guarantees: [
      {
        value: "NENHUMA",
        text: "Nenhuma",
      },
      {
        value: "FIADOR_COM_IMOVEL",
        text: "Fiador com Imóvel",
      },
      {
        value: "FIADOR",
        text: "Fiador",
      },
      {
        value: "SEGURO_FIANÇA",
        text: "Seguro Fiança",
      },
      {
        value: "DEPOSITO_ANTECIPADO",
        text: "Calção",
      },
    ],
    headers: [
      {
        text: "Descrição",
        align: "start",
        value: "description",
        filtering: true,
      },
      {
        text: "Disponibilidade",
        align: "start",
        value: "businesType",
        filtering: true,
      },
      {
        text: "Valor R$",
        align: "start",
        value: "price",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },
    removeAttachment(item) {
      http
        .delete(`immobile/${item.immobileId}/attachments/${item.id}`)
        .then(() => this.getAttachments(item.immobileId)),
        () => {
          this.snackbar.text = "Houve um erro ao REMOVER a Imagem";
          this.snackbar.opened = true;
          this.snackbar.color = "red";
        };
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    handleInput(value) {
      console.log(value);
      this.notaries.push(value);
    },
    handleFileUpload() {
      this.file = this.$refs.fileRef.files[0];
      this.fileName = this.$refs.fileRef.files[0].name;
      this.urlPreview = URL.createObjectURL(this.file);
    },
    openFileDialog() {
      document.getElementById("file-upload").click();
    },

    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },

    deleteOpen(itemId) {
      this.id = itemId;
      this.deleteDialog = true;
    },
    verifyDeletePermission() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.admin) return true;
      if (
        user &&
        user?.group?.permissions &&
        user?.group?.permissions.includes("immobile_tech.immobiles.delete_one")
      ) {
        return true;
      }
      return false;
    },
    deleteItem() {
      http.delete(`immobiles/${this.id}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.closeDialog();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
    getItems() {
      this.loading = true;
      http.get("immobiles").then((data) => {
        this.immobiles = data.data;
        this.loading = false;
      });
    },
    getOwners() {
      this.loading = true;
      http.get("immobile/owners").then((data) => {
        this.owners = data.data;
        this.loading = false;
      });
    },
    getAttachments(immobileId) {
      this.loading = true;
      http.get(`immobile/${immobileId}/attachments`).then((res) => {
        this.attachments = res.data;
      });
      this.loading = false;
    },
    getCondominiums() {
      this.loading = true;
      http.get("immobile/condominiums").then((data) => {
        this.condominiums = data.data;
        this.loading = false;
      });
    },
    getNotaries() {
      this.loading = true;
      http.get("immobiles/list/notary-offices").then((data) => {
        this.notaries = data.data;
        this.notaries.push({ registryName: "Adicionar Novo" });
        this.loading = false;
      });
    },
    registerOrUpdate() {
      this.loadingAction = true;

      if (this.immobile.businesType === "VENDA") {
        this.immobile.rentPrice = 0.0;
      }

      this.immobile.condominiumFee = TransformCurrencyToDecimalHelper(
        this.immobile.condominiumFee
      );

      this.immobile.salePrice = TransformCurrencyToDecimalHelper(
        this.immobile.salePrice
      );

      this.immobile.iptu = TransformCurrencyToDecimalHelper(this.immobile.iptu);

      this.immobile.advanceAmount = TransformCurrencyToDecimalHelper(
        this.immobile.advanceAmount
      );

      if (this.immobile.businesType === "ALUGUEL") {
        this.immobile.rentPrice = TransformCurrencyToDecimalHelper(
          this.immobile.rentPrice
        );
      }

      if (this.id) {
        http.put(`immobiles/${this.id}`, this.immobile).then(
          async () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            if (this.file) await this.upload(this.id);
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("immobiles", this.immobile).then(
          async (res) => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            if (this.file) await this.upload(res.data.id);
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      location.reload();
    },
    async upload(immobileId) {
      if (this.file) {
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("description", this.imageName);
        formData.append("path", "immobile/images");
        formData.append("public", 1);
        await http
          .post(`immobile/${immobileId}/attachments`, formData)
          .then(() => {
            this.getAttachments(immobileId);
          });
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    editItem(item) {
      this.id = item.id;
      this.immobile = item;
      this.getAttachments(item.id);
      this.openDialog = true;
    },
    searchCondominium() {
      http
        .get(`immobile/condominiums/${this.immobile.condominiumId}`)
        .then((res) => {
          this.immobile.address = res.data.address;
        });
    },
    searchCompany() {
      if (this.immobile.person.personType === "JURIDICA") {
        const cnpj = this.immobile.person.idDocument.replace(/([^\d])+/gim, "");
        axios
          .get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`)
          .then((res) => {
            const { data } = res;
            this.immobile.person.corporateName = data.razao_social;
            this.immobile.person.tradeName = data.nome_fantasia;
            this.immobile.person.professionOrMainActivity =
              data.cnae_fiscal + "-" + data.cnae_fiscal_descricao;
            this.immobile.address.street = data.logradouro;
            this.immobile.address.zone = data.bairro;
            this.immobile.address.city = data.municipio;
            this.immobile.address.state = data.uf;
            this.immobile.address.number = data.numero;
            this.immobile.address.complement = data.complemento;
            this.immobile.address.zipCode = data.cep;
          });
      }
    },
  },
  mounted() {
    this.getItems();
    this.getOwners();
    this.getCondominiums();
    this.getNotaries();
  },
});
</script>

<style>
.label-outlined {
  font-size: 12px;
  color: grey;
}
</style>
