<template>
  <div>
    <page-header :title="title" :menu="menu" class="mx-2" />
    <v-dialog v-model="groupDialog" width="300">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <p>Novo Grupo</p>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Nome"
                  v-model="group.name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Slug"
                  v-model="group.slug"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Descrição"
                  v-model="group.description"
                  required
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loadingAction"
            color="success"
            @click="registerGroup()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                outlined
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                @click="openDialog = true"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Nova Categoria</v-btn
              >
              <v-btn
                dark
                color="#2ca01c"
                @click="openDialog = true"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="categories"
          :search="search"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog v-model="openDialog" persistent max-width="800px">
        <v-card>
          <v-toolbar>
            <span class="headline" style="font-size: 16px !important"
              >Categorias de Produto</span
            >
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    label="Nome"
                    v-model="category.name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-row class="d-flex justify-space-between align-center">
                    <v-autocomplete
                      outlined
                      :items="groups"
                      item-value="id"
                      item-text="name"
                      label="Grupo"
                      v-model="category.mainGroupId"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                      required
                    >
                    </v-autocomplete>
                    <v-btn
                      icon
                      @click="groupDialog = true"
                      style="margin-top: -22px !important"
                    >
                      <v-icon>mdi-plus</v-icon></v-btn
                    >
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    label="Slug"
                    v-model="category.slug"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    label="Descrição"
                    v-model="category.description"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog()">
              Fechar
            </v-btn>
            <v-btn
              :loading="loadingAction"
              color="success"
              @click="registerOrUpdate()"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
export default Vue.extend({
  name: "Categories",
  components: { PageHeader, InternalMenu },
  data: () => ({
    title: "Categorias",
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Produtos",
        disabled: false,
        href: "/base/products",
      },
      {
        text: "Categorias",
        disabled: true,
        href: "/base/categories",
      },
    ],
    categories: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    groups: [],
    category: {
      name: "",
      mainGroupId: null,
      description: "",
      slug: "",
    },
    group: {
      name: "",
      slug: "",
      description: "",
    },
    groupDialog: false,
    openDialog: false,
    headers: [
      {
        text: "Nome",
        align: "start",
        value: "name",
        filtering: true,
      },
      {
        text: "Grupo",
        align: "start",
        value: "MainGroup.name",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
    search: null,
  }),
  methods: {
    getItems() {
      this.loading = true;
      http.get("product/groups").then((data) => {
        this.categories = data.data;
        this.loading = false;
      });
      this.loading = false;
    },
    registerGroup() {
      const find = this.groups.find((d) => d.name === this.group.name);
      if (find) {
        this.snackbar.text = "Já existe um Grupo com o nome informado";
        this.snackbar.color = "red";
        this.snackbar.opened = true;
        return;
      }
      http.post(`product/main-groups`, this.group).then(
        (res) => {
          const { data } = res;
          this.getGroups();
          this.category.mainGroupId = data.id;
          this.group = {
            name: "",
            description: "",
          };
          this.groupDialog = false;
        },
        () => {
          this.snackbar.text = "Erro ao Inserir o Grupo";
          this.snackbar.color = "red";
          this.snackbar.opened = true;
        }
      );
    },
    changeAuto(e) {
      //  console.log(e);
    },
    getGroups() {
      this.loading = true;
      http.get("product/main-groups").then((data) => {
        this.groups = data.data;
        this.loading = false;
      });
    },
    registerOrUpdate() {
      this.loadingAction = true;
      if (this.category.id) {
        const findPut = this.categories.find(
          (d) =>
            d.name === this.category.name &&
            d.id !== this.category.id &&
            d.mainGroupId === this.category.mainGroupId
        );
        if (findPut) {
          this.snackbar.text =
            "Já existe uma Categoria com o mesmo nome e grupo";
          this.snackbar.color = "red";
          this.snackbar.opened = true;
          this.loadingAction = false;
          return;
        }
        http.put(`product/groups/${this.category.id}`, this.category).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        const find = this.categories.find(
          (d) =>
            d.name === this.category.name &&
            d.mainGroupId === this.category.mainGroupId
        );
        if (find) {
          this.snackbar.text =
            "Já existe uma Categoria com o mesmo nome e grupo";
          this.snackbar.color = "red";
          this.snackbar.opened = true;
          this.loadingAction = false;
          return;
        }
        http.post("product/groups", this.category).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      this.openDialog = false;
      (this.category = {
        name: "",
        mainGroupId: null,
        description: "",
      }),
        this.getItems();
    },
    editItem(item) {
      this.category = item;
      this.openDialog = true;
    },
  },
  mounted() {
    this.getItems();
    this.getGroups();
  },
  watch: {
    search(val) {
      if (this.groups.length === 0) {
        this.groups.push({ id: "add-new", name: "Novo Grupo" });
      }
    },
  },
});
</script>