<template>
  <div>
    <page-header :title="title" :menu="menu" />
    <v-card>
      <v-card-text>
        <v-container>
          <br />
          <br />
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="d-flex justify-end align-end">
              <v-col cols="12" sm="4" class="mr-n8">
                <v-switch
                  v-model="product.visiblePrice"
                  label="Mostrar Preço?"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" class="mr-n8">
                <v-switch
                  v-model="product.stockControl"
                  label="Controlar Estoque"
                ></v-switch>
              </v-col>

              <v-col cols="12" sm="4" class="mt-n8">
                <span>Preço Avista</span>
                <money
                  :disabled="!product.visiblePrice"
                  class="money"
                  v-model="product.price"
                  v-bind="money"
                ></money>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start">
              <v-col cols="12" sm="4" class="mr-n8">
                <v-switch
                  v-model="product.enabledInstallment"
                  label="Habilitar Parcelamento?"
                ></v-switch>
              </v-col>

              <v-col cols="12" md="4" class="mr-8">
                <v-text-field
                  outlined
                  type="number"
                  v-model="product.installments"
                  label="Quantidade Parcelas"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" class="mt-n5">
                <span>Preço Parcelado</span>
                <money
                  :disabled="!product.visiblePrice || !product.enabledInstallment"
                  class="money"
                  v-model="product.installmentPrice"
                  v-bind="money"
                ></money>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3">
                <v-autocomplete
                  outlined
                  :items="sections"
                  v-model="product.section"
                  label="Vitrine*"
                  item-text="label"
                  item-value="id"
                  :loading="loading"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  v-model="product.name"
                  label="Nome"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  outlined
                  v-model="product.model"
                  label="Modelo"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" class="mt-n8">
                <v-autocomplete
                  outlined
                  :items="categories"
                  v-model="product.groupId"
                  label="Selecionae uma Categoria*"
                  item-text="name"
                  item-value="id"
                  :loading="loading"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-autocomplete>
              </v-col>

              <v-col cols="2" class="mt-n8" v-if="product.stockControl">
                <v-text-field
                  outlined
                  v-model="product.stock"
                  label="Estoque"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="mt-n8">
                <v-text-field
                  outlined
                  v-model="product.brand"
                  label="Marca"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="4" class="mt-n8">
                <v-text-field
                  outlined
                  v-model="product.ncm"
                  label="NCM"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="5" class="mt-n8">
                <v-text-field
                  outlined
                  v-model="product.barCode"
                  label="Código de Barras"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="3" class="mt-n8">
                <v-text-field
                  outlined
                  type="number"
                  v-model="product.shippingTime"
                  label="Prazo Entrega"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="3" class="mt-n8">
                <v-select
                  outlined
                  label="Prazo em"
                  v-model="product.shippingTimeType"
                  :items="[
                    { label: 'Dias', value: 'D' },
                    { label: 'Minutos', value: 'M' },
                  ]"
                  item-value="value"
                  item-text="label"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="8">
                <v-textarea
                  outlined
                  label="Descrição"
                  v-model="product.description"
                  rows="7"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn text class="black--text" @click.native="openFileDialog">
                  {{ fileName ? fileName : "Imagem" }}
                  <v-icon right dark>mdi-camera</v-icon>
                </v-btn>
                <input
                  ref="fileRef"
                  type="file"
                  id="file-upload"
                  style="display: none"
                  @change="handleFileUpload"
                />
                <v-img
                  height="160"
                  width="160"
                  :src="urlPreview"
                  @click.native="openFileDialog"
                ></v-img>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn text class="black--text" @click.native="openFileDialog2">
                  {{ fileName2 ? fileName2 : "Imagem" }}
                  <v-icon right dark>mdi-camera</v-icon>
                </v-btn>
                <input
                  ref="fileRef2"
                  type="file"
                  id="file-upload2"
                  style="display: none"
                  @change="handleFileUpload2"
                />
                <v-img
                  height="160"
                  width="160"
                  :src="urlPreview2"
                  @click.native="openFileDialog2"
                ></v-img>
              </v-col>
              <v-col cols="12">
                <tip-tap :value="product.fullDescription" />
              </v-col>
            </v-row>
            <v-card-actions class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text to="/pedbem/products"> Cancelar </v-btn>
              <v-btn
                dark
                :disabled="!valid"
                :loading="loading"
                width="200px"
                color="#2ca01c"
                @click="validate()"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import PageHeader from "@/components/core/PageHeader.vue";
import TipTap from "../core/tip-tap.vue";
export default Vue.extend({
  name: "ProductsForm",
  components: { Money, PageHeader, TipTap },
  data: () => ({
    valid: true,
    title: "Cadastro de Produto",
    visibleFields: true,
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Produtos",
        disabled: false,
        href: "/pedbem/products",
      },
      {
        text: "Cadastrar Produto",
        disabled: true,
        href: "/base/products",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    sections: [
      {
        id: "main",
        label: "Principal",
      },
      { id: "catalog", label: "Catálogo" },
      { id: "best_sellers", label: "Mais Vendidos" },
    ],
    products: [],
    categories: [],
    productId: null,
    file: "",
    fileName: "",
    urlPreview: "https://pedbem.blob.core.windows.net/pedbem/photo-99135_640.png",
    file2: "",
    fileName2: "",
    urlPreview2: "https://pedbem.blob.core.windows.net/pedbem/photo-99135_640.png",
    product: {
      name: "",
      description: "",
      fullDescription: "",
      stock: 5,
      shippingTime: 60,
      shippingTimeType: "M",
      groupId: "",
      price: 0.0,
      imageUrl: "",
      secondaryImageUrl: "",
      ncm: "",
      brand: "",
      bardCode: "",
      manufacturer: "",
      model: "",
      stockControl: false,
      visiblePrice: true,
      section: "main",
      enabledInstallment: true,
      installments: 6,
      installmentPrice: 0.0,
      enabledSale: true,
    },
    search: "",
    loading: false,
    productId: "",
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },

    handleFileUpload() {
      this.file = this.$refs.fileRef.files[0];
      this.fileName = this.$refs.fileRef.files[0].name;
      this.urlPreview = URL.createObjectURL(this.file);
    },
    handleFileUpload2() {
      this.file2 = this.$refs.fileRef2.files[0];
      this.fileName2 = this.$refs.fileRef2.files[0].name;
      this.urlPreview2 = URL.createObjectURL(this.file2);
    },
    openFileDialog() {
      document.getElementById("file-upload").click();
    },
    openFileDialog2() {
      document.getElementById("file-upload2").click();
    },
    async getItem() {
      const productId = this.$route.params.id;
      if (productId) {
        this.title = "Editar Produto";
        this.menu[2].text = "Editar Produto";
        await http
          .get(`store/718179c0-9036-494b-a9fd-9ee26c2bfc9b/products/${productId}`)
          .then(async (res) => {
            const { data } = await res;
            this.product = data;
            this.productId = data.id;
            this.urlPreview = data.imageUrl;
            this.urlPreview2 = data.secondaryImageUrl
          });
      }
    },
    getCategories() {
      http.get("product/groups").then((res) => {
        this.categories = res.data;
      });
    },

    // gcloud compute instances create pedbem --machine-type=n1-f1-micro --image-family=debian-10 --image-project=debian-cloud --preemptible --zone=southamerica-east1-a
    //gcloud compute ssh user@pedbem --zone=southamercica-east1-a
    //wget https://dl.google.com/go1.17.7.linux-amd64.tar.gz
    //sudo tar -C /usr/local -xzf go1.17.7.linux-amd64.tar.gz
    async registerOrUpdate() {
      this.loading = true;
      delete this.product.group;
      this.product.shippingTime = parseInt(this.product.shippingTime);
      this.product.installments = parseInt(this.product.installments);
      this.product.shippingTimeType = "Dia(s)";
      if (this.file) {
        const name = this.product.name.replace(/ /g, "-").toLowerCase();
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("identifier", name);
        await http.post("upload", formData).then(
          (d) => {
            this.product.imageUrl = d.data;
          },
          () => {
            this.snackbar.text =
              "Houve um erro ao fazer upload da IMAGEM, tente novamente";
            this.snackbar.opened = true;
            this.snackbar.color = "red";
          }
        );
      }
      if (this.file2) {
        const name = this.product.name.replace(/ /g, "-").toLowerCase();
        const formData = new FormData();
        formData.append("file", this.file2);
        formData.append("identifier", `${name}-secondary`);
        await http.post("upload", formData).then(
          (d) => {
            this.product.secondaryImageUrl = d.data;
          },
          () => {
            this.snackbar.text =
              "Houve um erro ao fazer upload da IMAGEM, tente novamente";
            this.snackbar.opened = true;
            this.snackbar.color = "red";
          }
        );
      }
      if (this.productId) {
        await http
          .put(
            `store/718179c0-9036-494b-a9fd-9ee26c2bfc9b/products/${this.productId}`,
            this.product
          )
          .then(
            () => {
              this.snackbar.text = "Produto atualizado com sucesso";
              this.snackbar.color = "success";
              this.snackbar.opened = true;
              this.loading = false;
              this.$router.push("/pedbem/products");
            },
            (err) => {
              const { response } = err;
              const message =
                typeof response?.data === "string"
                  ? response?.data
                  : response?.data?.title;
              this.snackbar.text = "Erro ao atualizar o produto: " + message;
              this.snackbar.color = "red";
              this.snackbar.opened = true;
              this.loading = false;
            }
          );
      } else {
        const name = this.product.name.replace(/ /g, "-").toLowerCase() + "-eletrosat";
        this.product.slug = name;
        await http.post("products", this.product).then(
          () => {
            this.snackbar.text = "Produto cadastrado com sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.loading = false;
            this.$router.push("/pedbem/products");
          },
          (err) => {
            const { response } = err;
            const message =
              typeof response?.data === "string" ? response?.data : response?.data?.title;
            this.snackbar.text = "Erro ao cadastrar o produto: " + message;
            this.snackbar.color = "red";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.getCategories();
    await this.getItem();
    await this.$root.$on("changeHtml", (value) => {
      this.product.fullDescription = value;
    });
  },
});
</script>
<style>
.money {
  background-color: rgb(0, 126, 0);
  height: 55px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
</style>
