<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-dialog :fullscreen="fullScreen" v-model="cartDialog" v-if="cart">
      <v-card elevation="0" color="white">
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Pedido {{ cart.identifier.toUpperCase() }}</v-toolbar-title>
          <v-btn text class="mr-4" @click="downloadWithCSS"
            ><v-icon>mdi-file-pdf-box</v-icon></v-btn
          >
          <v-btn icon right absolute dark>
            <v-icon @click="fullScreen ? (fullScreen = false) : (fullScreen = true)">{{
              setIconScreen()
            }}</v-icon>
            <v-icon @click="cartDialog = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div id="content">
          <div class="pdf">
            <v-container fluid style="background-color: white !important">
              <v-row class="d-flex justify-center">
                <v-card-subtitle class="overline"
                  >Dados do Pedido {{ cart.identifier }}</v-card-subtitle
                >
                <v-row>
                  <v-col cols="6">
                    <v-card-text>
                      <h6>Cliente:</h6>
                      <strong> {{ cart.customerName }}</strong>
                    </v-card-text>
                  </v-col>

                  <v-col cols="6">
                    <v-card-text>
                      <h6>Fone:</h6>
                      <strong> {{ cart.customerPhone }}</strong>
                    </v-card-text>
                  </v-col>

                  <v-col cols="6" class="mt-n8">
                    <v-card-text>
                      <h6>Data Abertura:</h6>
                      <strong>{{ formatDate(cart.createdAt) }}</strong>
                    </v-card-text>
                  </v-col>

                  <v-col cols="6" class="mt-n8">
                    <v-card-text>
                      <h6>Data Fatura:</h6>
                      <strong> {{ formatDate(cart.closedDate) }}</strong>
                    </v-card-text>
                  </v-col>
                  <v-col cols="6" class="mt-n8">
                    <v-card-text>
                      <h6>Prazo Entrega:</h6>
                      <strong> {{ formatDate(cart.deadlineTime) }}</strong>
                    </v-card-text>
                  </v-col>

                  <v-col cols="6" class="mt-n8">
                    <v-card-text>
                      <h6>Forma de Pagamento:</h6>
                      <strong>
                        {{
                          cart && cart.paymentMethod ? cart.paymentMethod.label : "-"
                        }}</strong
                      >
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" class="mt-n8">
                    <v-card-text>
                      <h6>Endereço Entrega:</h6>
                      <strong> {{ setAddress() }}</strong>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-row>

              <v-card-text class="overline mt-8">Itens do Pedido</v-card-text>
              <v-data-table
                dense
                :loading="loading"
                :headers="detailHeaders"
                :items="cart.items"
                :hide-default-footer="true"
                disable-pagination
                disable-sort
                class="mb-8"
              >
                <template v-slot:[`item.amountItem`]="{ item }">
                  <span>R$ {{ formatPrice(item.amountItem) }}</span>
                </template>
              </v-data-table>

              <v-card-subtitle class="overline mt-8">RESUMO</v-card-subtitle>
              <v-divider />
              <v-row>
                <v-col cols="4">
                  <v-card-subtitle>Total Itens</v-card-subtitle>
                  <v-card-title class="mt-n8"
                    ><strong>R$ {{ formatPrice(cart.amountItems) }}</strong></v-card-title
                  >
                </v-col>
                <v-col cols="4">
                  <v-card-subtitle>Taxa Entrega</v-card-subtitle>
                  <v-card-title class="mt-n8"
                    ><strong
                      >R$
                      {{
                        formatPrice(cart.deliveryRate ? cart.deliveryRate : 0.0)
                      }}</strong
                    ></v-card-title
                  >
                </v-col>
                <v-col cols="4">
                  <v-card-subtitle>Total do Pedido</v-card-subtitle>
                  <v-card-title class="mt-n8"
                    ><strong>R$ {{ formatPrice(cart.amountTotal) }}</strong></v-card-title
                  >
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0">
          <v-row class="d-flex justify-space-around">
            <v-col cols="4">
              <v-text-field
                solo
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                height="40"
              ></v-text-field>
            </v-col>
            <v-spacer />
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="carts"
          :search="search"
        >
          <template v-slot:[`item.id`]="{ item }">
            <span> {{ item.id.split("-")[0].toUpperCase() }}</span>
          </template>
          <template v-slot:[`item.amountTotal`]="{ item }">
            <span>R$ {{ formatPrice(item.amountTotal) }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="getCart(item)"> <v-icon>mdi-eye</v-icon></v-btn>
          </template>

          <template v-slot:[`item.closedDate`]="{ item }">
            <span>{{ formatDate(item.closedDate) }}</span>
          </template>

          <template v-slot:[`item.deliveryDate`]="{ item }">
            <span>{{ formatDate(item.deliveryDate) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { VMoney } from "v-money";
import moment from "moment";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default Vue.extend({
  name: "AllSales",
  components: { PageHeader, InternalMenu },
  data: () => ({
    title: "Pedidos Entregues",
    menuItems: [],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Pedidos Entregues",
        disabled: true,
        href: "/pedbem/carts",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    fullScreen: false,
    exportMode: false,
    carts: [],
    cart: "",
    cartDialog: false,
    search: "",
    loading: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",

    headers: [
      {
        text: "Data Fatura",
        align: "start",
        value: "closedDate",
        filtering: true,
      },
      {
        text: "Data Entrega",
        value: "deliveryDate",
        filtering: true,
      },
      {
        text: "Número",
        align: "start",
        value: "id",
        filtering: true,
      },

      {
        text: "Cliente",
        align: "start",
        value: "customerName",
        filtering: true,
      },

      {
        text: "Forma Pagamento",
        value: "paymentMethod.label",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "amountTotal",
        filtering: true,
      },

      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],

    detailHeaders: [
      {
        text: "Quantidade",
        align: "start",
        value: "quantity",
        filtering: true,
      },
      {
        text: "Produto",
        align: "start",
        value: "product.name",
        filtering: true,
      },
      {
        text: "Comentários",
        align: "start",
        value: "notes",
        filtering: true,
      },
      {
        text: "Total Item R$",
        value: "amountItem",
        filtering: true,
      },
    ],
  }),
  methods: {
    downloadWithCSS() {
      html2canvas(document.getElementById("content")).then(function (canvas) {
        var wid;
        var hgt;
        var img = canvas.toDataURL(
          "image/jpeg",
          (wid = canvas.width),
          (hgt = canvas.height)
        );
        var hratio = hgt / wid;
        var doc = new jsPDF("p", "pt", "a4", false);
        var width = doc.internal.pageSize.width;
        var height = width * hratio;
        doc.addImage(img, "JPEG", 10, 10, width - 20, height, "NONE", "SLOW");
        window.open(doc.output("bloburl"));
        //
        //  doc.save("Test.pdf");
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    setAddress() {
      const cart = this.cart;
      if (cart && cart.street)
        return `${cart?.street}, ${cart?.number} - ${cart?.zone} - ${cart?.city}/${cart?.state}`;
      return "Endereço não definido";
    },

    getCart(item) {
      this.cart = item;
      this.cartDialog = true;
    },

    getItems() {
      this.loading = true;
      http.get("sales?status=delivered&canceled=0").then(
        (data) => {
          this.carts = data.data;
          this.loading = false;
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text =
            "Hove um erro ao buscar os registros. Atualize a página por favor";
          this.snackbar.opened = true;
        }
      );
    },
  },
  directives: { money: VMoney },
  mounted() {
    this.getItems();
  },
});
</script>
<style scoped>
fieldset {
  width: 97%;
  height: 90%;
  margin: auto;
  border-radius: 4px;
  padding: 15px;
}
</style>
