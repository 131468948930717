<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
    </v-row>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO Será
          Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="80">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                filled
                outlined
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="products"
          :search="search"
        >
          <template v-slot:[`item.unitPrice`]="{ item }">
            <span>
              {{
                item.installments === 0
                  ? formatPrice(item.unitPrice)
                  : formatPrice(item.installmentPrice)
              }}</span
            >
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span> {{ dateFormat(item.createdAt) }}</span>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <span> {{ formatPrice(item.amount) }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text small :to="`/wishlist/${item.id}/edit`">
              <v-icon small class="mr-2">mdi-pencil</v-icon></v-btn
            >
            <v-icon small class="mr-2" @click="deleteOpen(item.id)">mdi-eraser</v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import moment from "moment";
export default Vue.extend({
  name: "Reservations",
  components: { Money, PageHeader, InternalMenu },
  data: () => ({
    valid: true,
    title: "Lista de Reservas",
    menuItems: [],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Lista de Reservas",
        disabled: true,
        href: "/base/products",
      },
    ],
    deleteDialog: false,
    products: [],
    search: "",
    loading: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },

    headers: [
      {
        text: "Data",
        align: "start",
        value: "createdAt",
        filtering: true,
      },
      {
        text: "Nome",
        align: "start",
        value: "reserveName",
        filtering: true,
      },
      {
        text: "Fone",
        align: "start",
        value: "reservePhone",
        filtering: true,
      },
      {
        text: "Item",
        align: "start",
        value: "list.title",
        filtering: true,
      },
      {
        text: "Loja",
        align: "start",
        value: "list.store",
        filtering: true,
      },
      {
        text: "Pagamento",
        value: "payment",
        align: "start",

        filtering: true,
      },
      {
        text: "QTD",
        value: "quantity",
        align: "start",

        filtering: true,
      },
      {
        text: "Vlr UN",
        value: "unitPrice",
        align: "start",

        filtering: true,
      },
      {
        text: "Total",
        value: "amount",
        align: "start",
        filtering: true,
      },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(value) {
      return moment(value).format("DD/MM");
    },
    deleteOpen(itemId) {
      this.productId = itemId;
      this.deleteDialog = true;
    },
    getItems() {
      this.loading = true;
      http.get("wishlist/reservations").then(
        (data) => {
          this.products = data.data;
          this.loading = false;
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text =
            "Hove um erro ao buscar os registros. Atualize a página por favor";
          this.snackbar.opened = true;
        }
      );
    },
    deleteItem() {
      http.delete(`wishlists/${this.productId}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
  },
  mounted() {
    this.getItems();
  },
});
</script>
