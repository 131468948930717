<template>
  <div>
    <v-dialog :fullscreen="fullScreen" v-model="show" v-if="cart">
      <v-card>
        <v-toolbar
          dark
          color="primary"
          flat
          style="padding-right: 10px !important"
        >
          <v-toolbar-title class="overline"
            >Venda Nº {{ cart.saleNumber }}</v-toolbar-title
          >
          <v-btn icon @click="print"><v-icon>mdi-printer</v-icon></v-btn>
          <v-btn icon right absolute dark>
            <v-icon
              @click="fullScreen ? (fullScreen = false) : (fullScreen = true)"
              >{{ setIconScreen() }}</v-icon
            >
            <v-icon @click="show = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card elevation="0">
          <div id="print">
            <v-container>
              <div style="border: 1px grey solid; mardin: 10px; padding: 15px">
                <v-card-title style="font-size: 14px; margin-top: -20px"
                  >PEDIDO {{ cart.saleNumber }}</v-card-title
                >
                <v-row class="d-flex justify-center">
                  <v-row>
                    <v-col cols="6" class="mt-n6">
                      <v-card-text>
                        <h6>Cliente:</h6>
                        {{ cart.customer.person.corporateName }}
                      </v-card-text>
                    </v-col>

                    <v-col cols="6" class="mt-n6">
                      <v-card-text>
                        <h6>Fone:</h6>
                        {{ cart.customer.contact.phoneMobile }}
                      </v-card-text>
                    </v-col>

                    <v-col cols="6" style="margin-top: -40px">
                      <v-card-subtitle>
                        <h6>Data Abertura:</h6>
                        {{ formatDate(cart.createdAt) }}
                      </v-card-subtitle>
                    </v-col>

                    <v-col cols="6" style="margin-top: -40px">
                      <v-card-subtitle>
                        <h6>Plano de Pagamento</h6>
                        {{ cart.paymentPlan }}
                      </v-card-subtitle>
                    </v-col>
                    <v-col cols="6" style="margin-top: -40px">
                      <v-card-subtitle>
                        <h6>Status</h6>
                        {{ cart.status }}
                      </v-card-subtitle>
                    </v-col>

                    <v-col cols="6" style="margin-top: -40px">
                      <v-card-subtitle>
                        <h6>Vendedor:</h6>
                        {{
                          cart && cart.salesman
                            ? cart.salesman.person.corporateName
                            : "Indefinido"
                        }}
                      </v-card-subtitle>
                    </v-col>
                    <v-col cols="12" style="margin-top: -40px">
                      <v-card-subtitle>
                        <h6>Endereço</h6>
                        {{ setAddress() }}
                      </v-card-subtitle>
                    </v-col>
                  </v-row>
                </v-row>

                <v-divider />

                <span style="margin-top: -20px; font-size: 10px"
                  >ITENS DA VENDA</span
                >
                <v-data-table
                  :loading="loading"
                  :headers="productHeaders"
                  :items="items"
                  disable-pagination
                  hide-default-footer
                  style="
                    font-size: 12px;
                    color: black;
                    text-transform: caption;
                    padding: 1px;
                  "
                >
                  <template v-slot:[`item.totalPrice`]="{ item }">
                    <span> {{ formatPrice(item.totalPrice) }}</span>
                  </template>

                  <template v-slot:[`item.product.stock`]="{ item }">
                    <span>
                      {{
                        item && item.product && item.product.stockControl
                          ? item.product.stock
                          : "Ilimitado"
                      }}</span
                    >
                  </template>
                </v-data-table>
                <br />

                <span class="overline text-caption ma-4"
                  >PLANO DE PAGAMENTO</span
                >
                <br />
                <v-data-table
                  :loading="loading"
                  :headers="instalmentHeaders"
                  :items="instalments"
                  disable-pagination
                  hide-default-footer
                  style="font-size: 10px; color: black; text-transform: caption"
                >
                  <template v-slot:[`item.amount`]="{ item }">
                    <span> R$ {{ formatPrice(item.amount) }}</span>
                  </template>
                  <template v-slot:[`item.number`]="{ item }">
                    <span>
                      {{
                        `${item.frequencyNumber}/${item.frequencyTotal}`
                      }}</span
                    >
                  </template>
                  <template v-slot:[`item.dueDate`]="{ item }">
                    <span> {{ dateFormat(item.dueDate) }}</span>
                  </template>
                </v-data-table>

                <br />
                <v-row>
                  <v-col cols="4">
                    <v-card elevation="0" color="#3c3c3a">
                      <v-card-subtitle>Total</v-card-subtitle>
                      <v-card-title class="mt-n8"
                        >R$ {{ formatPrice(cart.amountItems) }}</v-card-title
                      >
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card elevation="0" color="#3c3c3a">
                      <v-card-subtitle>Desconto</v-card-subtitle>
                      <v-card-title class="mt-n8"
                        >R$ {{ formatPrice(cart.discount) }}</v-card-title
                      >
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card elevation="0" color="#3c3c3a">
                      <v-card-subtitle>Total Líquido</v-card-subtitle>
                      <v-card-title class="mt-n8"
                        >R$ {{ formatPrice(cart.amountLiquid) }}</v-card-title
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import moment from "moment";
import http from "@/services/http";

export default Vue.extend({
  props: ["cart", "address", "visible", "items", "instalments"],
  data: () => ({
    title: "Detalhe da Venda",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Todos os Pedidos",
            to: "/pedbem/delivered-sales",
            permission: "gerentor.sales.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Pedidos em Andamento",
        disabled: true,
        href: "/pedbem/carts",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    fullScreen: false,
    search: "",
    loading: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },

    headers: [
      {
        text: "Data Fatura",
        align: "start",
        value: "closedDate",
        filtering: true,
      },
      {
        text: "Número",
        align: "start",
        value: "orderNumber",
        filtering: true,
      },
      {
        text: "Status",
        value: "deliveryStatus",
        filtering: true,
      },
      {
        text: "Forma Pagamento",
        value: "paymentMethod",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "amount",
        filtering: true,
      },

      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],

    productHeaders: [
      {
        text: "Descrição",
        align: "start",
        value: "product.name",
        filtering: true,
      },
      {
        text: "QTD",
        align: "start",
        value: "quantity",
        filtering: true,
      },
      {
        text: "Estoque",
        align: "start",
        value: "product.stock",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "totalPrice",
        filtering: true,
      },
    ],
    instalmentHeaders: [
      {
        text: "Nº",
        align: "start",
        value: "number",
        filtering: true,
      },
      {
        text: "Vencimento",
        align: "start",
        value: "dueDate",
        filtering: true,
      },
      {
        text: "Forma Pagamento",
        align: "start",
        value: "paymentMethod.title",
        filtering: true,
      },
      {
        text: "Valor R$",
        value: "amount",
        filtering: true,
      },
    ],
  }),
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },

    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    setAddress() {
      const cart = this.cart;
      if (cart && cart.customer && cart.customer.address)
        return `${cart.customer.address.street}, ${cart.customer.address.number} - ${cart.customer.address.zone} - ${cart.customer.address.city}/${cart.customer.address.state}`;
      if (cart && cart.deliveryType === "onstore")
        return "Pedido será retirado na loja";
      return "Endereço não definido";
    },
    print() {
      const prtHtml = document.getElementById("print").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
});
</script>
<style scoped>
fieldset {
  width: 97%;
  height: 90%;
  margin: auto;
  border-radius: 4px;
  padding: 15px;
}
</style>
