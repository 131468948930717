<template>
  <div>
    <v-row
      align="center"
      justify="center"
      style="height: 100vh"
      class="mt-n6 mr-n16 ml-n16"
    >
      <v-col
        cols="12"
        class="primary background d-flex flex-column justify-center align-center"
      >
        <v-card
          class="card-login"
          width="400"
          height="auto"
          color="#1a265a"
          dark
          style="opacity: 0.9"
        >
          <v-card-title class="overline text-h6 mt-4 justify-center">
            <v-img
              alt="Procommerce"
              class="shrink mt-1"
              contain
              min-width="100"
              src="@/assets/gerentor_green.png"
              width="130"
            />
          </v-card-title>
          <v-form class="mt-8 ml-6 mr-6">
            <v-col cols="12">
              <v-text-field
                outlined
                v-model="email"
                placeholder="Usuário"
                label="Usuário"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="mt-n8">
              <v-text-field
                outlined
                v-model="password"
                placeholder="Senha"
                type="password"
                label="Senha"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn @click="login" :loading="loadingLogin" color="success" width="100%"
                >Entrar</v-btn
              >
            </v-col>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import http from "./../services/http";
import Vue from "vue";
import axios from "axios";
import BaserUrl from "@/services/base-url";
export default {
  name: "Login",

  data: () => ({
    email: "",
    password: "",
    loadingLogin: false,
  }),
  methods: {
    login() {
      this.loadingLogin = true;
      const payload = {
        username: this.email,
        password: this.password,
        platform: "pedbem",
      };
      const data = axios.post(BaserUrl + "/auth/login", payload).then(
        (data) => {
          localStorage.setItem("access_token", data.data.token);
          localStorage.setItem("user", JSON.stringify(data.data.user));
          //   localStorage.setItem("congregationId", data.data.user.congregationId);
          location.reload();
          this.loadingLogin = false;
          location.href = "/";
        },
        (err) => {
          this.loadingLogin = false;
          alert("Credenciais Inválidas");
        }
      );
    },
  },
};
</script>
<style scoped>
.background {
  background: url("https://cdn.pixabay.com/photo/2015/02/05/08/12/stock-624712_960_720.jpg");
  background-position: center;
  background-size: cover;

  height: 105%;
  width: 105%;
  right: -2.5%;
  left: -2.5%;
  top: -2.5%;
  bottom: -2.5%;
}
.card-login {
  width: 400px !important;
  height: 400px !important;
}
@media only screen and (max-width: 600px) {
  .card-login {
    width: 75% !important;
    height: auto;
  }
}
</style>
