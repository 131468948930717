















































/*eslint-disable */
import Vue from "vue";
export default Vue.extend({
  name: "Menu",
  data: () => ({
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Cadastros",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Usuários",
            to: "/users",
            permission: "digitech_plus.users.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Clientes",
            to: "/immobile/customers",
            permission: "immobile_tech.immobile.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Colaboradores",
            to: "/immobile/employees",
            permission: "immobile_tech.immobile.employees.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Proprietários",
            to: "/immobile/owners",
            permission: "digitech_plus.immobile.owners.get_many",
          },
          {
            icon: "",
            title: "Perfís de Acesso",
            to: "/user/group-users",
            permission: "digitech_plus.user.groups.get_many",
          },
        ],
      },
      {
        icon: "mdi-storefront",
        title: "Produtos",
        permission: "pedbem.products.get_many",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Produtos",
            to: "/products",
            permission: "pedbem.products.get_many",
          },
          {
            icon: "",
            title: "Categorias",
            to: "/categories",
            permission: "pedbem.product.categories.get_many",
          },
        ],
      },
      {
        icon: "mdi-storefront",
        title: "Imóveis",
        permission: "immobile_tech.immobiles.get_many",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Condominios",
            to: "/immobile/condominiums",
            permission: "immobile_tech.immobile.condominiums.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Imóveis",
            to: "/immobiles",
            permission: "immobile_tech.immobiles.get_many",
          },
        ],
      },
      {
        icon: "mdi-mdi-sale",
        title: "Pedidos",
        permission: "pedbem.sales.get_many",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Todos",
            to: "/delivered-orders",
            permission: "pedbem.sales.get_many",
          },
          {
            icon: "",
            title: "Em Andamento",
            to: "/orders-in-progress",
            permission: "pedbem.sales.get_many",
          },
        ],
      },
      {
        icon: "mdi-finance",
        title: "Financeiro",
        permission: "digitech_plus.finance.movements.get_many",
        subs: [
          {
            icon: "",
            title: "Lançamentos",
            to: "/finance/movements",
            permission: "digitech_plus.finance.movements.get_many",
          },
          {
            icon: "",
            title: "Classificações",
            to: "/finance/categories",
            permission: "digitech_plus.finance.categories.get_many",
          },
          {
            icon: "",
            title: "Contas Financeiras",
            to: "/finance/accounts",
            permission: "digitech_plus.finance.accounts.get_many",
          },
          {
            icon: "",
            title: "Extrato Financeiro",
            to: "/finance/account-reports",
          },
          {
            icon: "",
            title: "Contas a Pagar",
            to: "/finance/account-payables",
          },
          {
            icon: "",
            title: "Métodos de Pagamento",
            to: "/finance/payment-methods",
          },
        ],
      },
      {
        icon: "mdi-chart-bar",
        title: "Fornecedores",
        subs: [
          {
            icon: "",
            title: "Fornecedores",
            to: "/company/suppliers",
          },
          {
            icon: "",
            title: "Contas a Pagar",
            to: "/finance/account-payables",
          },
        ],
      },
    ],
  }),
  methods: {
    validPermission(permission: any) {
      let user = localStorage.getItem("user") as any;
      if (user) user = JSON.parse(user) as any;
      if (!user) return false;
      if (user.admin) return true;
      const permissions = user?.group?.permissions?.map((d: any) => d.id);
      if (!permissions || !permissions?.length) return false;
      if (permissions.includes(permission) || (user && user.admin)) return true;
      else return false;
    },
  },
});
