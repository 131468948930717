<template>
  <v-app>
    <DashboardCoreAppBar v-if="token" />
    <DashboardCoreDrawer v-if="token" />
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
/*eslint-disable */

export default {
  name: "App",
  components: {
    DashboardCoreAppBar: () => import("@/components/core/Navbar"),
    DashboardCoreDrawer: () => import("@/components/core/Nav"),
    Menu: () => import("@/components/core/Menu"),
  },
  data: () => ({
    token: "",
  }),
  mounted() {
    this.token = localStorage.getItem("access_token");
  },
};
</script>