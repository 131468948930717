<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="titlePage" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="exportModal" width="400">
      <v-btn color="primary" @click="downloadWithCSS" block>Salvar PDF</v-btn>
    </v-dialog>
    <v-row justify="center">
      <v-dialog v-model="searchReport" persistent max-width="1000">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title class="overline"
              >Cáuculo de Comissão</v-toolbar-title
            >
          </v-toolbar>
          <v-container fluid>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4" class="mt-8">
                  <v-select
                    outlined
                    v-model="employeeId"
                    :items="employees"
                    item-value="id"
                    item-text="person.corporateName"
                    label="Vendedor"
                  ></v-select>
                </v-col>
                <v-col cols="6" md="3" class="mt-8">
                  <v-text-field
                    outlined
                    v-model="startDate"
                    type="date"
                    label="Data Inicial"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="3" class="mt-8">
                  <v-text-field
                    outlined
                    v-model="endDate"
                    type="date"
                    label="Data Final"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="mt-8">
                  <v-select
                    outlined
                    v-model="calculationForm"
                    :items="['VENDA', 'RECEBIMENTO']"
                    label="Forma de Cálculo"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="searchReport = false">
                CANCELAR
              </v-btn>
              <v-btn color="green" text @click="calculateComission()">
                CALCULAR
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <v-container fluid>
      <div id="content">
        <v-card>
          <v-toolbar elevation="0" height="auto">
            <v-row>
              <v-col cols="12" md="3" class="mt-6">
                <v-select
                  outlined
                  v-model="employeeId"
                  :items="employees"
                  item-value="id"
                  item-text="person.corporateName"
                  label="Vendedor"
                ></v-select>
              </v-col>
              <v-col cols="6" md="3" class="mt-6">
                <v-text-field
                  outlined
                  v-model="startDate"
                  type="date"
                  label="Data Inicial"
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="3" class="mt-6">
                <v-text-field
                  outlined
                  v-model="endDate"
                  type="date"
                  label="Data Final"
                ></v-text-field>
              </v-col>

              <v-col cols="4" md="1" class="mt-7" v-if="!modeExport">
                <v-btn icon dark color="green" outlined @click="getItems()">
                  <v-icon> mdi-find-replace</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="4" md="1" class="mt-7" v-if="!modeExport">
                <v-btn
                  icon
                  dark
                  color="blue"
                  outlined
                  @click="searchReport = true"
                >
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="4" md="1" class="mt-7" v-if="!modeExport">
                <v-btn icon dark color="red" outlined @click="prepareDownload">
                  <v-icon>mdi-file-pdf-box-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>

          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="transactions"
            :search="search"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.comission`]="{ item }">
              <span>R$ {{ formatPrice(item.comission, item.type) }}</span>
            </template>
            <template v-slot:[`item.referenceDate`]="{ item }">
              <span>{{ dateFormat(item.referenceDate) }}</span>
            </template>
          </v-data-table>
        </v-card>
        <br />
        <v-card>
          <v-toolbar flat v-if="credits.length">
            <v-toolbar-title class="overline"
              >Comissão por vendedor</v-toolbar-title
            >
          </v-toolbar>
          <v-toolbar-items
            class="elevation-2"
            v-for="(credit, ic) in credits"
            :key="ic"
          >
            <v-row
              class="d-flex justify-space-between"
              style="
                padding-left: 20px !important;
                padding-right: 20px !important;
              "
            >
              <v-card-subtitle>{{ credit.name }} </v-card-subtitle>
              <v-card-subtitle
                >R$ {{ formatPrice(credit.total, "C") }}</v-card-subtitle
              >
            </v-row>
          </v-toolbar-items>
          <v-toolbar-items
            class="elevation-2"
            v-for="(debit, id) in debits"
            :key="id"
          >
            <v-row
              class="d-flex justify-space-between"
              style="
                padding-left: 20px !important;
                padding-right: 20px !important;
              "
            >
              <v-card-subtitle>{{ debit.name }} </v-card-subtitle>
              <v-card-subtitle
                >R$ {{ formatPrice(debit.comission, "D") }}</v-card-subtitle
              >
            </v-row>
          </v-toolbar-items>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-toolbar-items class="elevation-2 green">
                <v-row
                  class="d-flex justify-space-between"
                  style="
                    padding-left: 20px !important;
                    padding-right: 20px !important;
                  "
                >
                  <v-card-title class="overline text-white"
                    >Comissão</v-card-title
                  >
                  <v-card-title class="overline text-white text-h6"
                    >R$ {{ formatPrice(amount, "C") }}</v-card-title
                  >
                </v-row>
              </v-toolbar-items>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-toolbar-items class="elevation-2 red">
                <v-row
                  class="d-flex justify-space-between"
                  style="
                    padding-left: 20px !important;
                    padding-right: 20px !important;
                  "
                >
                  <v-card-title class="overline text-white"
                    >Devoluções</v-card-title
                  >
                  <v-card-title class="overline text-white text-h6"
                    >R$ {{ formatPrice(totalDebit, "D") }}</v-card-title
                  >
                </v-row>
              </v-toolbar-items>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-toolbar-items class="elevation-2 primary">
                <v-row
                  class="d-flex justify-space-between"
                  style="
                    padding-left: 20px !important;
                    padding-right: 20px !important;
                  "
                >
                  <v-card-title class="overline text-white">Saldo</v-card-title>
                  <v-card-title class="overline text-white text-h6"
                    >R$ {{ formatPrice(amount, "C") }}</v-card-title
                  >
                </v-row>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import moment from "moment";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default Vue.extend({
  title: "FinancialMovement",
  components: { PageHeader, InternalMenu },
  data: () => ({
    titlePage: "Extrato de Comissões",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Funcionários",
            to: "/base/employees",
            permission: "gerentor.person.employees.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Nova Venda",
            to: "/base/sale/create",
            permission: "gerentor.sales.create_one",
          },
          {
            icon: "mdi-storeftont",
            title: "Faturamento",
            to: "/base/sales/billing",
            permission: "gerentor.sales.update_one",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Transações",
        disabled: true,
        href: "/finance/transaction/instalment",
      },
    ],
    employees: [],
    transactions: [],
    totalCredit: 0.0,
    totalDebit: 0.0,
    balance: 0.0,
    credits: [],
    debits: [],
    amount: 0.0,
    searchReport: false,
    search: "",
    calculationForm: "VENDA",
    loading: false,
    loadingAction: false,
    modeExport: false,
    exportModal: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    id: "",
    employeeId: null,
    startDate: moment().startOf("month").format("yyyy-MM-DD"),
    endDate: moment().format("yyyy-MM-DD"),
    openDialog: false,
    headers: [
      {
        text: "Data",
        align: "start",
        value: "referenceDate",
        filtering: true,
      },
      {
        text: "Descrição",
        align: "start",
        value: "description",
        filtering: true,
      },
      {
        text: "Forma de Apuração",
        align: "start",
        value: "calculationForm",
        filtering: true,
      },
      {
        text: "Vendedor",
        align: "start",
        value: "employee.person.corporateName",
        filtering: true,
      },
      {
        text: "Valor R$",
        align: "start",
        value: "comission",
        filtering: true,
      },
    ],
  }),
  methods: {
    formatPrice(value, type) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async prepareDownload() {
      this.modeExport = true;
      this.exportModal = true;
      // await this.downloadWithCSS();
      // this.modeExport = await false;
    },
    downloadWithCSS() {
      html2canvas(document.getElementById("content")).then(function (canvas) {
        var wid;
        var hgt;
        var img = canvas.toDataURL(
          "image/png",
          (wid = canvas.width),
          (hgt = canvas.height)
        );
        var hratio = hgt / wid;
        var doc = new jsPDF("p", "pt", "a4");
        var width = doc.internal.pageSize.width;
        var height = width * hratio;
        doc.addImage(img, "JPEG", 10, 20, width - 20, height);
        doc.save("Test.pdf");
      });
      this.exportModal = false;
      this.modeExport = false;

      /*     var doc = new jsPDF("p", "mm", "a4");

      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();
      var canvasElement = document.createElement("canvas");
      html2canvas(this.$refs.content, { canvas: canvasElement }).then(function (
        canvas
      ) {
        const img = canvas.toDataURL("image/jpeg", 0.8);
        doc.addImage(img, "JPEG", 0, 0, width, height);
        doc.save("sample.pdf");
      });*/
    },
    download() {
      /*  const doc = new jsPDF();

      const contentHtml = this.$refs.content.innerHTML;
      doc.fromHTML(contentHtml, 15, 15, {
        width: 170,
      });
      doc.save("sample.pdf");*/
      const contentHtml = this.$refs.content.innerHTML;
       /*  var doc = new jsPDF();
      const html = `<h1>Comissões</h1>
      <h2>Total R$ ${this.amount}</h2>`;
      doc.html(html, {
        callback: function (doc) {
          doc.save();
        },
        x: 10,
        y: 10,
      });*/
    },

    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    calculateComission() {
      const payload = {
        employeeId: this.employeeId,
        calculationForm: this.calculationForm,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      http.post("gerentor/comission-transactions", payload).then(() => {
        this.searchReport = false;
        this.getItems();
      });
    },
    getItems() {
      this.loading = true;
      let url = `gerentor/comission-transactions?startDate=${this.startDate}&endDate=${this.endDate}`;
      if (this.employeeId) url = `${url}&employeeId=${this.employeeId}`;
      http.get(url).then((data) => {
        this.transactions = data.data.transactions;
        this.credits = data.data.employees;
        this.debits = data.data.debits;
        this.amount = data.data.amount.totalComission;
        this.loading = false;
      });
    },
    getEmployees() {
      this.loading = true;
      http.get("gerentor/employees").then((data) => {
        this.employees = data.data;
        this.loading = false;
      });
      this.loading = false;
    },
  },
  mounted() {
    this.getEmployees();
    this.getItems();
  },
});
</script>
<style scoped>
.text-white {
  color: white !important;
}
</style>