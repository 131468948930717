











































/* eslint-disable */
import Vue from "vue";
export default Vue.extend({
  name: "Nav",
  data: () => ({
    expand: false,
    user: {
      admin: false,
      accountType: null,
    },
    items: [
      {
        title: "Produtos",
        icon: "mdi-food",
        link: "/pedbem/products",
        permission: ["admin"],
      },
      {
        title: "Reservas",
        icon: "mdi-wallet-giftcard",
        link: "/wishlist-reservations",
        permission: ["admin", "management"],
      },
      {
        title: "Lista de Presentes",
        icon: "mdi-gift",
        link: "/wishlist",
        permission: ["admin", "management"],
      },
      {
        title: "Grupos de Produtos",
        icon: "mdi-format-list-checks",
        link: "/base/categories",
        permission: ["admin"],
      },
      {
        title: "Em Andamento",
        icon: "mdi-moped-outline",
        link: "/pedbem/sales-in-progress",
        permission: ["admin"],
      },
      {
        title: "Entregues",
        icon: "mdi-check-outline",
        link: "/pedbem/delivered-sales",
        permission: ["admin"],
      },
      {
        title: "Cancelados",
        icon: "mdi-close-outline",
        link: "/pedbem/canceled-sales",
        permission: ["admin"],
      },
      {
        title: "Contatos",
        icon: "mdi-email",
        link: "/pedbem/contacts",
        permission: ["admin"],
      },
    ],
  }),
  methods: {
    getUser() {
      let user = localStorage.getItem("user") as any;
      if (user) this.user = JSON.parse(user) as any;
    },
    validPermission(permissions: any) {
      if (permissions === "all") return true;
      if (!this.user) return false;
      if (this.user.admin) return true;
      if (
        permissions &&
        permissions.length &&
        permissions.includes(this.user.accountType)
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getUser();
    this.$root.$on("changeDrawe", () => {
      this.expand = !this.expand;
    });
    const us = localStorage.getItem("user");
    if (us && typeof us === "string") {
      this.expand = JSON.parse(us).visibleMenu;
    }
  },
});
