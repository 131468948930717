<template>
  <div>
    <page-header :title="title" :menu="menu" />
    <v-card>
      <v-card-text>
        <v-container>
          <br />
          <br />
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="d-flex justify-end">
              <v-col cols="12" sm="4" class="mt-6">
                <v-text-field
                  outlined
                  type="number"
                  v-model="product.installments"
                  label="Quantidade Parcelas"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <span>Preço Parcelado</span>
                <money
                  class="money"
                  v-model="product.installmentPrice"
                  v-bind="money"
                ></money>
              </v-col>

              <v-col cols="12" sm="4">
                <span>Preço Avista</span>
                <money class="money" v-model="product.unitPrice" v-bind="money"></money>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4">
                <v-autocomplete
                  outlined
                  :items="sections"
                  v-model="product.sectionId"
                  label="Lista*"
                  item-text="title"
                  item-value="id"
                  :loading="loading"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  outlined
                  v-model="product.title"
                  label="Nome"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  outlined
                  v-model="product.quantity"
                  label="QTD"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  outlined
                  v-model="product.store"
                  label="Loja"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Endereço Loja"
                  v-model="product.storeAddress"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Link Externno"
                  v-model="product.storeLink"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Informações Parcelamento"
                  v-model="product.textPayment"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-btn text class="black--text" @click.native="openFileDialog">
                  {{ fileName ? fileName : "Imagem" }}
                  <v-icon right dark>mdi-camera</v-icon>
                </v-btn>
                <input
                  ref="fileRef"
                  type="file"
                  id="file-upload"
                  style="display: none"
                  @change="handleFileUpload"
                />
                <v-img
                  height="160"
                  width="160"
                  :src="urlPreview"
                  @click.native="openFileDialog"
                ></v-img>
              </v-col>
            </v-row>
            <v-card-actions class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text to="/wishlist"> Cancelar </v-btn>
              <v-btn
                dark
                :disabled="!valid"
                :loading="loading"
                width="200px"
                color="#2ca01c"
                @click="validate()"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import PageHeader from "@/components/core/PageHeader.vue";
import TipTap from "../core/tip-tap.vue";
export default Vue.extend({
  title: "ProductsForm",
  components: { Money, PageHeader, TipTap },
  data: () => ({
    valid: true,
    title: "Cadastro de Item",
    visibleFields: true,
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Produtos",
        disabled: false,
        href: "/pedbem/wishlists",
      },
      {
        text: "Cadastrar Item",
        disabled: true,
        href: "/base/wishlists",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    sections: [],
    wishlists: [],
    categories: [],
    productId: null,
    file: "",
    fileName: "",
    urlPreview: "https://pedbem.blob.core.windows.net/pedbem/photo-99135_640.png",
    product: {
      title: "",
      store: "",
      storeAddress: "",
      quantity: 1,
      unitPrice: 0.0,
      available: true,
      externalLink: "",
      sectionId: null,
      enabledInstallment: true,
      installments: 1,
      installmentPrice: 0.0,
      textPayment: "",
      storeLink: null,
    },
    search: "",
    loading: false,
    productId: "",
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },

    handleFileUpload() {
      this.file = this.$refs.fileRef.files[0];
      this.fileName = this.$refs.fileRef.files[0].title;
      this.urlPreview = URL.createObjectURL(this.file);
    },
    openFileDialog() {
      document.getElementById("file-upload").click();
    },
    async getItem() {
      const productId = this.$route.params.id;
      if (productId) {
        this.title = "Editar Item";
        this.menu[2].text = "Editar Item";
        await http.get(`wishlists/${productId}`).then(async (res) => {
          const { data } = await res;
          this.product = data;
          this.productId = data.id;
          this.urlPreview = data.externalLink;
        });
      }
    },

    async registerOrUpdate() {
      this.loading = true;
      this.product.quantity = parseInt(this.product.quantity);
      this.product.installments = parseInt(this.product.installments);
      if (this.product.quantity >= 1) {
        this.product.available = true;
      }

      if (this.file) {
        const title = this.product.title.replace(/ /g, "-").toLowerCase();
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("identifier", title);
        await http.post("upload", formData).then(
          (d) => {
            this.product.externalLink = d.data;
          },
          () => {
            this.snackbar.text =
              "Houve um erro ao fazer upload da IMAGEM, tente novamente";
            this.snackbar.opened = true;
            this.snackbar.color = "red";
          }
        );
      }
      if (this.productId) {
        await http.put(`/wishlists/${this.productId}`, this.product).then(
          () => {
            this.snackbar.text = "Item atualizado com sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.loading = false;
            this.$router.push("/wishlist");
          },
          (err) => {
            const { response } = err;
            const message =
              typeof response?.data === "string" ? response?.data : response?.data?.title;
            this.snackbar.text = "Erro ao atualizar o produto: " + message;
            this.snackbar.color = "red";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      } else {
        await http.post("wishlists", this.product).then(
          () => {
            this.snackbar.text = "Item cadastrado com sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.loading = false;
            this.$router.push("/wishlist");
          },
          (err) => {
            const { response } = err;
            const message =
              typeof response?.data === "string" ? response?.data : response?.data?.title;
            this.snackbar.text = "Erro ao cadastrar o produto: " + message;
            this.snackbar.color = "red";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      }
      this.loading = false;
    },
    getSections() {
      http.get("store-sections").then((a) => {
        this.sections = a.data;
      });
    },
  },
  async mounted() {
    await this.getSections();
    await this.getItem();
  },
});
</script>
<style>
.money {
  background-color: rgb(0, 126, 0);
  height: 55px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
</style>
