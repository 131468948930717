<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-dialog v-model="dialogCancelation" persistent max-width="900">
      <v-card>
        <v-toolbar>
          <v-btn icon dark @click="dialogCancelation = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar>Solicitar Cancelamento</v-toolbar>
        </v-toolbar>
        <v-container fluid>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  v-model="payloadCancellation.cancellationInfo"
                  label="Motivo do Cancelamento"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-checkbox
                  label="Contactou o CLIENTE sobre o cancelamento e confirma o cancelamento?"
                  v-model="payloadCancellation.canceled"
                />
              </v-col>
              <span
                >Recomendamos solicitar o cancelamento, quando NÃO HOUVER possibilidade
                alguma de atender o pedido, e que antes se faça contato com o cliente.
                Lembrando que SOLICITAÇÕES DE CANCELAMENTO POR SUA PARTE, PODE AFETAR SUA
                AVALIAÇÃO NA PLATAFORMA</span
              >
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="dialogCancelation = false"> FECHAR </v-btn>
            <v-btn
              :disabled="
                !payloadCancellation.canceled || !payloadCancellation.cancellationInfo
              "
              color="green"
              text
              @click="cancelationCart"
              >SOLICITAR CANCELAMENTO
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="fullScreen" v-model="cartDialog" v-if="cart">
      <v-card elevation="0" color="white">
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Pedido {{ cart.identifier.toUpperCase() }}</v-toolbar-title>
          <v-btn text class="mr-4" @click="downloadWithCSS"
            ><v-icon>mdi-file-pdf-box</v-icon></v-btn
          >
          <v-btn icon right absolute dark>
            <v-icon @click="fullScreen ? (fullScreen = false) : (fullScreen = true)">{{
              setIconScreen()
            }}</v-icon>
            <v-icon @click="cartDialog = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div id="content">
          <div class="pdf">
            <v-container fluid style="background-color: white !important">
              <v-row class="d-flex justify-center">
                <v-card-subtitle class="overline"
                  >Dados do Pedido {{ cart.identifier }}</v-card-subtitle
                >
                <v-row>
                  <v-col cols="6">
                    <v-card-text>
                      <h6>Cliente:</h6>
                      <strong> {{ cart.customerName }}</strong>
                    </v-card-text>
                  </v-col>

                  <v-col cols="6">
                    <v-card-text>
                      <h6>Fone:</h6>
                      <strong> {{ cart.customerPhone }}</strong>
                    </v-card-text>
                  </v-col>

                  <v-col cols="6" class="mt-n8">
                    <v-card-text>
                      <h6>Data Abertura:</h6>
                      <strong>{{ formatDate(cart.createdAt) }}</strong>
                    </v-card-text>
                  </v-col>

                  <v-col cols="6" class="mt-n8">
                    <v-card-text>
                      <h6>Data Fatura:</h6>
                      <strong> {{ formatDate(cart.closedDate) }}</strong>
                    </v-card-text>
                  </v-col>
                  <v-col cols="6" class="mt-n8">
                    <v-card-text>
                      <h6>Prazo Entrega:</h6>
                      <strong> {{ formatDate(cart.deadlineTime) }}</strong>
                    </v-card-text>
                  </v-col>

                  <v-col cols="6" class="mt-n8">
                    <v-card-text>
                      <h6>Forma de Pagamento:</h6>
                      <strong>
                        {{
                          cart && cart.paymentMethod ? cart.paymentMethod.label : "-"
                        }}</strong
                      >
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" class="mt-n8">
                    <v-card-text>
                      <h6>Endereço Entrega:</h6>
                      <strong> {{ setAddress() }}</strong>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-row>

              <v-card-text class="overline mt-8">Itens do Pedido</v-card-text>
              <v-data-table
                dense
                :loading="loading"
                :headers="detailHeaders"
                :items="cart.items"
                :hide-default-footer="true"
                disable-pagination
                disable-filtering
                disable-sort
                class="mb-8"
              >
                <template v-slot:[`item.amountItem`]="{ item }">
                  <span>R$ {{ formatPrice(item.amountItem) }}</span>
                </template>
              </v-data-table>

              <v-card-subtitle class="overline mt-8">RESUMO</v-card-subtitle>
              <v-divider />
              <v-row>
                <v-col cols="4">
                  <v-card-subtitle>Total Itens</v-card-subtitle>
                  <v-card-title class="mt-n8"
                    ><strong>R$ {{ formatPrice(cart.amountItems) }}</strong></v-card-title
                  >
                </v-col>
                <v-col cols="4">
                  <v-card-subtitle>Taxa Entrega</v-card-subtitle>
                  <v-card-title class="mt-n8"
                    ><strong
                      >R$
                      {{
                        formatPrice(cart.deliveryRate ? cart.deliveryRate : 0.0)
                      }}</strong
                    ></v-card-title
                  >
                </v-col>
                <v-col cols="4">
                  <v-card-subtitle>Total do Pedido</v-card-subtitle>
                  <v-card-title class="mt-n8"
                    ><strong>R$ {{ formatPrice(cart.amountTotal) }}</strong></v-card-title
                  >
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>

    <v-container fluid>
      <v-row>
        <v-col cols="12" md="4">
          <v-card color="purple" min-height="800">
            <v-toolbar dark flat color="purple darken-1">
              <v-toolbar-title class="overline">Recebidos </v-toolbar-title>
            </v-toolbar>
            <div v-for="(one, oneIndex) in cartsOneStage" :key="oneIndex">
              <v-container fluid>
                <v-card>
                  <v-card-text>
                    <h6>Cliente:</h6>
                    {{ one.customerName }}

                    <h6>Prazo:</h6>
                    {{ formatDate(one.deadlineTime) }}

                    <h6>Informações:</h6>
                    {{ one.items.length }} Item - Total R$
                    {{ formatPrice(one.amountTotal) }}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn dark @click="changeStage(one, 2)" x-small color="primary"
                      >Iniciar Preparo</v-btn
                    >
                    <v-btn dark @click="getCart(one)" x-small color="pink"
                      >Detalhes</v-btn
                    >
                    <v-btn dark @click="openCancelationModal(one)" x-small color="red"
                      >Cancelar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-container>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card dark color="primary" min-height="800">
            <v-toolbar flat color="primary darken-1">
              <v-toolbar-title class="overline">Em Preparo </v-toolbar-title>
            </v-toolbar>
            <div v-for="(two, twoIndex) in cartsTwoStage" :key="twoIndex">
              <v-container fluid>
                <v-card>
                  <v-card-text>
                    <h6>Cliente:</h6>
                    {{ two.customerName }}

                    <h6>Prazo:</h6>
                    {{ formatDate(two.deadlineTime) }}

                    <h6>Informações:</h6>
                    {{ two.items.length }} Item - Total R$
                    {{ formatPrice(two.amountTotal) }}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn dark @click="changeStage(two, 3)" x-small color="primary"
                      >Iniciar entrega</v-btn
                    >
                    <v-btn dark @click="getCart(two)" x-small color="pink"
                      >Detalhes</v-btn
                    >
                    <v-btn dark @click="openCancelationModal(two)" x-small color="red"
                      >Cancelar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-container>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card dark color="success" min-height="800">
            <v-toolbar flat color="success darken-1">
              <v-toolbar-title class="overline">Saiu para Entrega </v-toolbar-title>
            </v-toolbar>

            <div v-for="(three, threeIndex) in cartsThreeStage" :key="threeIndex">
              <v-container fluid>
                <v-card>
                  <v-card-text>
                    <h6>Cliente:</h6>
                    {{ three.customerName }}

                    <h6>Prazo:</h6>
                    {{ formatDate(three.deadlineTime) }}

                    <h6>Informações:</h6>
                    {{ three.items.length }} Item - Total R$
                    {{ formatPrice(three.amountTotal) }}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn dark @click="changeStage(three, 4)" x-small color="primary"
                      >Finalizar</v-btn
                    >
                    <v-btn dark @click="getCart(three)" x-small color="pink"
                      >Detalhes</v-btn
                    >
                    <v-btn dark @click="openCancelationModal(three)" x-small color="red"
                      >Cancelar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-container>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { VMoney } from "v-money";
import moment from "moment";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default Vue.extend({
  name: "ShoppingCartDelivered",
  components: { PageHeader, InternalMenu },
  data: () => ({
    title: "Pedidos em Andamento",
    menuItems: [],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Pedidos em Andamento",
        disabled: true,
        href: "/pedbem/carts",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    fullScreen: false,
    carts: [],
    cartsOneStage: [],
    cartsTwoStage: [],
    cartsThreeStage: [],
    cart: "",
    cartDialog: false,
    search: "",
    loading: false,
    dialogCancelation: false,
    payloadCancellation: {
      cancellationInfo: "",
      canceled: false,
    },
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",

    headers: [
      {
        text: "Data Fatura",
        align: "start",
        value: "closedDate",
        filtering: true,
      },
      {
        text: "Número",
        align: "start",
        value: "orderNumber",
        filtering: true,
      },
      {
        text: "Status",
        value: "deliveryStatus",
        filtering: true,
      },
      {
        text: "Forma Pagamento",
        value: "paymentMethod",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "amount",
        filtering: true,
      },

      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],

    detailHeaders: [
      {
        text: "Quantidade",
        align: "start",
        value: "quantity",
        filtering: true,
      },
      {
        text: "Produto",
        align: "start",
        value: "product.name",
        filtering: true,
      },
      {
        text: "Comentários",
        align: "start",
        value: "notes",
        filtering: true,
      },
      {
        text: "Total Item R$",
        value: "amountItem",
        filtering: true,
      },
    ],
  }),
  methods: {
    downloadWithCSS() {
      html2canvas(document.getElementById("content")).then(function (canvas) {
        var wid;
        var hgt;
        var img = canvas.toDataURL(
          "image/jpeg",
          (wid = canvas.width),
          (hgt = canvas.height)
        );
        var hratio = hgt / wid;
        var doc = new jsPDF("p", "pt", "a4", false);
        var width = doc.internal.pageSize.width;
        var height = width * hratio;
        doc.addImage(img, "JPEG", 10, 10, width - 20, height, "NONE", "SLOW");
        window.open(doc.output("bloburl"));
        //
        //  doc.save("Test.pdf");
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    changeStage(cart, stage) {
      const payload = {
        deliveryStage: stage,
      };
      http.post(`/sales/${cart.id}/change`, payload).then(() => {
        this.getItems();
      });
    },
    openCancelationModal(cart) {
      this.cart = cart;
      this.dialogCancelation = true;
    },
    cancelationCart() {
      http.post(`sales/${this.cart.id}/change`, this.payloadCancellation).then(
        () => {
          this.snackbar.color = "success";
          this.snackbar.text = "Pedido CANCELADO COM SUCESSO!";
          this.snackbar.opened = true;
          this.openCancelationModal = false;
          this.getItems();
        },
        (err) => {
          const { response } = err;
          this.snackbar.color = "red";
          this.snackbar.text =
            "Hove um erro ao solicitar o CANCELAMENTO: " + response?.data?.message;
          this.snackbar.opened = true;
          this.openCancelationModal = false;
          this.getItems();
        }
      );
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    setAddress() {
      const cart = this.cart;
      if (cart && cart.addressStreet)
        return `${cart.addressStreet}, ${cart.addressNumber} - ${cart.addressZone} - ${cart.addressCity}/${cart.addressState}`;
      if (cart && cart.deliveryType === "onstore") return "Pedido será retirado na loja";
      return "Endereço não definido";
    },

    getCart(item) {
      this.cart = item;
      this.cartDialog = true;
    },

    getItems() {
      this.loading = true;
      http.get("sales?status=in-porgress&canceled=0").then(
        (data) => {
          this.carts = data.data;
          this.cartsOneStage = this.carts.filter((d) => d.deliveryStage === 1);
          this.cartsTwoStage = this.carts.filter((d) => d.deliveryStage === 2);
          this.cartsThreeStage = this.carts.filter((d) => d.deliveryStage === 3);
          this.loading = false;
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text =
            "Hove um erro ao buscar os registros. Atualize a página por favor";
          this.snackbar.opened = true;
        }
      );
    },
  },
  directives: { money: VMoney },
  mounted() {
    this.getItems();
    setInterval(() => {
      this.getItems();
    }, 15000);
  },
});
</script>
<style scoped>
fieldset {
  width: 97%;
  height: 90%;
  margin: auto;
  border-radius: 4px;
  padding: 15px;
}
.pdf {
  padding: 10px;
  margin: 10px;
  border: 1px silver solid;
  border-radius: 5px;
}
</style>
