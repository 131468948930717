<template>
  <div>
    <v-app-bar app color="#1a265a" dark elevation="0" height="75">
      <v-app-bar-nav-icon @click.stop="changeDrawe"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          v-if="!visibleTitle"
          alt="Gerentor ADMIN"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="@/assets/gerentor_green.png"
          width="130"
        />
        <!--
        <h1 class="overline text-h6" v-if="visibleTitle">Gerentor</h1>-->
      </div>
      <v-spacer></v-spacer>
      <!--
      <v-autocomplete
        rounded
        v-model="searchRoute"
        class="search-input"
        :items="menuFilter"
        item-text="title"
        item-value="link"
        flat
        hide-no-data
        hide-details
        label="Menu"
        solo-inverted
        append-icon="mdi-magnify"
        @change="searchTo()"
        height="20"
      >
      </v-autocomplete>-->
      <v-spacer></v-spacer>
      <v-btn text @click="logout">
        <v-icon size="22px">mdi-account-arrow-right-outline</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>
<script>
/*eslint-disable */
import Vue from "vue";
import http from "@/services/http";
export default Vue.extend({
  name: "Navbar",
  data: () => ({
    searchRoute: "",
    visibleTitle: true,
    user: {
      visibleMenu: true,
      darkTheme: false,
      admin: false,
      group: {
        permissions: [],
      },
    },
    menuFilter: [],
    menu: [
      {
        title: "Produtos Delivery",
        icon: "mdi-food",
        link: "/pedbem/products",
        permission: "pedbem.products.get_many",
      },
      {
        title: "Grupos de Produtos",
        icon: "mdi-cart",
        link: "/base/categories",
        permission: "pedbem.carts.get_many",
      },
      {
        title: "Contatos",
        icon: "mdi-email",
        link: "/pedbem/contacts",
        permission: "pedbem.carts.get_many",
      },
    ],
  }),
  methods: {
    searchTo() {
      this.$router.push(this.searchRoute);
    },
    validPermission() {
      if (this.user.admin) {
        this.menuFilter = this.menu;
        return;
      }
      const permissions = this.user?.group?.permissions?.map((d) => d.id);
      if (!permissions.length) {
        return;
      }
      for (const item of this.menu) {
        if (
          permissions.includes(item.permission) ||
          item.permission === "all" ||
          (this.user && this.user.admin)
        ) {
          this.menuFilter.push(item);
        }
      }
    },
    changeDrawe() {
      this.visibleTitle = !this.visibleTitle;
      this.$root.$emit("changeDrawe");
    },
    logout() {
      localStorage.clear();
      location.reload();
      location.href = "/login";
    },
    setDark(isDark) {
      const payload = {
        isDark,
      };
      http.put("users/me/preferences", payload).then((res) => {
        const { data } = res;
        localStorage.setItem("user", JSON.stringify(data));
        location.reload();
      });
    },
    setMenu(value) {
      this.user.visibleMenu = value;
      const payload = {
        visibleMenu: value,
      };
      http.put("users/me/preferences", payload).then((res) => {
        const { data } = res;
        localStorage.setItem("user", JSON.stringify(data));
        location.reload();
      });
    },
    init() {
      const us = localStorage.getItem("user");
      if (us && typeof us === "string") {
        const vs = JSON.parse(us);
        this.visibleTitle = !vs.visibleMenu;
        this.user = vs;
      }
    },
  },
  mounted() {
    this.init();
  },
});
</script>
