<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-row dense>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          :md="card.flex"
        >
          <v-card class="ma-6">
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-icon
                  class="primary--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  size="70px"
                  @click="navigate(card.to)"
                >
                  {{ card.icon }}
                </v-icon>
              </v-col>
              <v-col cols="12" class="d-flex justify-center mt-n4">
                <v-btn :to="card.to" class="primary--text" text block>{{
                  card.title
                }}</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <sales />
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import finance from "@/assets/sale/payment.png";
import cart from "@/assets/sale/add.png";
import list from "@/assets/sale/list.png";
import Sales from "@/components/base/Sales.vue";
export default Vue.extend({
  name: "Vendas",
  components: { PageHeader, InternalMenu, Sales },
  data: () => ({
    title: "Vendas",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Categorias de Produtos",
            to: "/base/categories",
            permission: "gerentor.product.categories.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Clientes",
            to: "/base/customers",
            permission: "gerentor.person.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Colaboradores",
            to: "/immobile/employees",
            permission: "gerentor.person.employees.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Vendas",
        disabled: true,
        href: "/base/sales",
      },
    ],

    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    cards: [
      /*   {
        title: "Pre-fab homes",
        src: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
        flex: 12,
      },*/
      {
        title: "Nova Venda",
        src: cart,
        icon: "mdi-plus",
        flex: 4,
        to: "/base/sale/create",
      },
      {
        title: "Faturamento",
        src: finance,
        icon: "mdi-finance",
        flex: 4,
        to: "/base/sales/billing",
      },
      {
        title: "Relatórios",
        src: list,
        icon: "mdi-sale",
        flex: 4,
        to: "/base/sales",
      },
    ],
  }),
  methods: {
    navigate(to) {
      this.$router.push(to);
    },
  },
});
</script>

