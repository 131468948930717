import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/components/delivery/Reservations.vue'),
  },
  {
    path: '/users',
    name: 'Usuários',
    component: () => import('@/components/Users.vue'),
  },
  {
    path: '/user/group-users',
    name: 'Grupos de Usuários',
    component: () => import('@/components/GroupUsers.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },

  {
    path: '/finance/payment-methods',
    name: 'Métodos de Pagamento',
    component: () => import('@/components/finance/PaymentMethod.vue'),
  },
  {
    path: '/finance/transaction/create',
    name: 'Nova Transação Financeira',
    component: () => import('@/components/finance/FinancialTransactionForm.vue'),
  },

  /* Immobiles */
  {
    path: '/immobile/owners',
    name: 'Cadastro de Proprietários',
    component: () => import('@/components/immobile/Owner.vue'),
  },
  {
    path: '/immobile/customers',
    name: 'Cadastro de Clientes',
    component: () => import('@/components/immobile/Customer.vue'),
  },
  {
    path: '/immobile/condominiums',
    name: 'Cadastro de Condominios',
    component: () => import('@/components/immobile/Condominium.vue'),
  },
  {
    path: '/base/employees',
    name: 'Cadastro de Colaboradores',
    component: () => import('@/components/immobile/Employee.vue'),
  },
  {
    path: '/immobiles',
    name: 'Cadastro de Imóveis',
    component: () => import('@/components/immobile/Immobile.vue'),
  },
  /* base */
  {
    path: '/base/customers',
    name: 'Clientes',
    component: () => import('@/components/base/Customer.vue'),
  },
  {
    path: '/base/customers/create',
    name: 'Cadastro de Clientes',
    component: () => import('@/views/CustomerForm.vue'),
  },
  {
    path: '/base/customers/:id/edit',
    name: 'Edição de Clientes',
    component: () => import('@/views/CustomerForm.vue'),
  },
  {
    path: '/base/suppliers',
    name: 'Fornecedores',
    component: () => import('@/components/base/Supplier.vue'),
  },
  {
    path: '/base/products',
    name: 'Produtos',
    component: () => import('@/components/base/Product.vue'),
  },
  {
    path: '/base/categories',
    name: 'Categorias de Produto',
    component: () => import('@/components/base/Categories.vue'),
  },
  {
    path: '/base/product/:id/edit',
    name: 'Editar Produto',
    component: () => import('@/components/base/ProductForm.vue'),
  },
  {
    path: '/base/product/create',
    name: 'Novo Produto',
    component: () => import('@/components/base/ProductForm.vue'),
  },
  {
    path: '/base/sales/home',
    name: 'Home Vendas',
    component: () => import('@/components/base/SalesHome.vue'),
  },
  {
    path: '/base/sales/billing',
    name: 'Faturamento Vendas',
    component: () => import('@/components/base/SalesBilling.vue'),
  },
  {
    path: '/base/stock-transactions',
    name: 'Transação de Estoque',
    component: () => import('@/components/base/StockTransactions.vue'),
  },
  {
    path: '/base/stock-transactions/create',
    name: 'Nova Transação de Estoque',
    component: () => import('@/components/base/StockTransactionForm.vue'),
  },
  {
    path: '/base/stock-transactions/:id/edit',
    name: 'Editar Transação de Estoque',
    component: () => import('@/components/base/StockTransactionForm.vue'),
  },
  {
    path: '/gerentor/comission-transactions',
    name: 'Relatórios de Comissões',
    component: () => import('@/components/base/ComissionTransaction.vue'),
  },
  // Gerentor
  {
    path: '/pedbem/products',
    name: 'Produtos Gerentor',
    component: () => import('@/components/delivery/DeliveryProduct.vue'),
  },
  {
    path: '/wishlist/register',
    name: 'Criar Lista de Presentes',
    component: () => import('@/components/delivery/WishListForm.vue'),
  },
  {
    path: '/wishlist',
    name: 'Lista de Presentes',
    component: () => import('@/components/delivery/WishList.vue'),
  },
  {
    path: '/wishlist/:id/edit',
    name: 'Editar Lista de Presentes',
    component: () => import('@/components/delivery/WishListForm.vue'),
  },
  {
    path: '/wishlist-reservations',
    name: 'Reservas',
    component: () => import('@/components/delivery/Reservations.vue'),
  },
  {
    path: '/pedbem/product/register',
    name: 'Novo Produto Gerentor',
    component: () => import('@/components/delivery/DeliveryProductForm.vue'),
  },
  {
    path: '/pedbem/product/:id/edit',
    name: 'Editar Produto Gerentor',
    component: () => import('@/components/delivery/DeliveryProductForm.vue'),
  },
  {
    path: '/pedbem/delivered-sales',
    name: 'Pedidos Entregues',
    component: () => import('@/components/delivery/carts/ShoppingCart.vue'),
  },
  {
    path: '/pedbem/sales-in-progress',
    name: 'Pedidos Em Andamento',
    component: () => import('@/components/delivery/carts/ShoppingCartInProgress.vue'),
  },
  {
    path: '/pedbem/canceled-sales',
    name: 'Pedidos Cancelados',
    component: () => import('@/components/delivery/carts/CanceledSales.vue'),
  },
  {
    path: '/pedbem/contacts',
    name: 'Contatos',
    component: () => import('@/components/delivery/Contacts.vue'),
  },
];

const router = new VueRouter({
  mode: !process.env.IS_ELECTRON ? 'history' : 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
